import axios from "axios";

//const remoteUrl ="https://mc-hillsapi.onrender.com";
const localUrl ="http://localhost:3045";
const Axios = axios.create({
  baseURL:localUrl,
  headers:{
    "Content-Type":"application/json",
  }
});

const AxiosForm = axios.create({
  baseURL:localUrl,
  headers:{
    "Content-Type":"multipart/form-data",
  }
});

export {Axios, AxiosForm};
