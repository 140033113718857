import React from 'react';
import styled from 'styled-components';
import { Link, NavLink } from 'react-router-dom';



export const NotFound = () => {
  return (
    <Wrapper className='text-center'>
      <nav className="navbar navbar-expand-lg  border-bottom">
        <NavLink className="navbar-brand" to="/admin/dashboard">
          <img src={require("../utils/Img/Edited.png")} alt="" className="img-fluid ms-2  " /></NavLink>
      </nav>
      <section>
        <img src={require("../utils/Img/404-error.png")} alt="" className="img-fluid" />
        <h1 className='mb-0'>Sorry Page Not Found!</h1>
        <p className="mt-0">Ooops! The page you requested for does not exist.</p>
        <Link to="/home" className='btn-back btn'>Back to Home</Link>
      </section>
    </Wrapper>
  )
}

const Wrapper = styled.div `
  nav{
    margin:0 auto;
  }
  section{
    padding:10px;
    margin:4% auto;

    h1{
      font-weight:800;
      margin-top:15px;

      @media screen and (max-width:330px){
        font-size:24px;
      }
    }
    .btn-back{
      background:#3F518F;
      color:#fff;
      font-weight:800;
      transition:ease 1s;
      margin-bottom:25px;
    }
    .btn-back:hover{
      background:#450681;
    }
  }
`;