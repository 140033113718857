import React from 'react';
import styled from 'styled-components';


export const ProjectVideoPreview = ({source}) => {
  return (
    <Wrapper>
      <div className="preview  embed-responsive  ">
        <iframe src={source} 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          title='Project Preview'
          allowFullScreen
        />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div `
  .preview{

    // iframe{
    //   height:420px;
    //   width:78%;

    //   @media screen and (max-width:768px){

    //   }
    // }
  }
`;