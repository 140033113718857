import React from 'react';
import { Bar, Doughnut,Line,Pie} from 'react-chartjs-2';
import {Chart, ArcElement, CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js'


const LineChart = ({data:{items,title,labels}}) => {

  Chart.register(
    ArcElement, 
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
  );
  const options = {
    plugins:{
      legend:{
        position: 'top',
      },
      title:{
        display:true,
        text:`${title}`
      },
      maintainAspectRatio: false
    }
  }
  const data ={
    labels,
    datasets: [{
      label: items.name,
      data: items.data,
      fill: true,
      borderColor: items.color,
      tension: 0.5,
      
    }]
  }
  return(
    <>
      <Line options={options} data={data}/>
    </>
  )
}

const PieChart = ({data:{items,title,labels}}) => {

  Chart.register(
    ArcElement, 
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  const options = {
    plugins:{
      legend:{
        position: 'top',
      },
      title:{
        display:true,
        text:`${title}`
      },
      maintainAspectRatio: false
    }
  }
  const data ={
    labels,
    datasets: [{
      label: items.name,
      data: items.data,
      backgroundColor:items.bg,
      hoverOffset: 4
    }]
  }
  return(
    <>
      <Pie options={options} data={data} />
    </>
  )
}

const DoughnutChart = ({data:{items,title,background,label}}) => {

  Chart.register(
    ArcElement, 
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
    
  const options = {
    plugins:{
      legend:{
        position: 'top',
      },
      title:{
        display:true,
        text:`${title}`
      }
    }
  }

  return(
    <Doughnut options={options} data={{
        labels:label,
        datasets:[{
          data:items,
          backgroundColor:background,
          
        }]
      }} 
    />
  )
}
const BarChart = ({data:{items,title,labels}}) => {

  Chart.register(
    ArcElement, 
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  
  const options = {
    plugins:{
      legend:{
        position: 'top',
      },
      title:{
        display:true,
        text:`${title}`
      }
    },
    maintainAspectRatio: false
  }
  return(
    <>
      <Bar id="chart" options={options} data={{
        labels,
        datasets:items.map((item,index) => {
          return{
            label:item.label,
            data:(item.data),
            backgroundColor:item.bg,
            borderColor: item.bg,
            fill:true
          }
        }),
        borderWidth:4,
        minBarLength:8,
        barPercentage: 0.5,
        barThickness: 6,
        maxBarThickness: 8,
      }} />
    </>
  )
}

export {
  BarChart,
  PieChart,
  LineChart,
  DoughnutChart
}
