import React from 'react';
import styled from "styled-components";
import client2 from "../utils/Img/service/client2.png";
import client3 from "../utils/Img/service/client3.png";
import client4 from "../utils/Img/service/client4.png";
import client5 from "../utils/Img/service/client5.png";
import client6 from "../utils/Img/service/client6.png";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const Service = () => {

  const settings ={
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrow:true,
  }
  return (
    <Wrapper className='container-fluid'>
      <div className="hero container-fluid">
        {/* <p className="lead">We provide best quality IT services to our clients.</p> */}
      </div>
      <div className="container">
        <div className="row it_service mt-3">
          <div className="col-md-7 col-sm-12">
            <h1 className="mb-0">We Offer a Wide <br />Variety of IT Services</h1>
            <p className="mt-0 w-md-75">
              We help businesses elevate their value through custom software development,
              product design, QA and consultancy services.
            </p>
          </div>
        </div>
        <div className="row my-4">
          <div className="col-md-4 col-sm-12">
            <div className="">
              <div className="d-flex ">
                <img src={require("../utils/Img/icons/web.png")} alt='' className="img-fluid icon"/>
                <h4 className="my-auto">Web Development</h4>
              </div>
              <div>
                <p>
                  Our proven track record of good and reliable software development skills makes us stand out from competitors. We set the pace, others follow.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="">
              <div className="d-flex ">
                <img src={require("../utils/Img/icons/Ui-icon.png")} alt='' className="img-fluid icon"/>
                <h4 className="my-auto">UI/UX Design</h4>
              </div>
              <div>
                <p>
                  Build the product you need on time with an experienced team that uses a clear and effective design process.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="">
              <div className="d-flex ">
                <img src={require("../utils/Img/icons/mobile.png")} alt='' className="img-fluid icon"/>
                <h4 className="my-auto">Mobile Development</h4>
              </div>
              <div>
                <p>
                  We create complex enterprise solutions, ensure reliable software integration, while modernizing your legacy system.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row my-4">
          <div className="col-md-4 col-sm-12">
            <div className="">
              <div className="d-flex ">
                <img src={require("../utils/Img/icons/QA-test.png")} alt='' className="img-fluid icon"/>
                <h4 className="my-auto">QA & Testing</h4>
              </div>
              <div>
                <p>
                  Turn to our experts to perform comprehensive, multi-stage testing and auditing of your software.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="">
              <div className="d-flex ">
                <img src={require("../utils/Img/icons/Chart.png")} alt='' className="img-fluid icon"/>
                <h4 className="my-auto">IT Consultancy</h4>
              </div>
              <div>
                <p>
                  Trust our top minds to eliminate workflow pain points, implement new tech, and consolidate app portfolios.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="">
              <div className="d-flex ">
                <img src={require("../utils/Img/icons/badge.png")} alt='' className="img-fluid icon"/>
                <h4 className="my-auto">Dedicated Team</h4>
              </div>
              <div>
                <p>
                  Over the past decade, our customers succeeded by leveraging Xpress Dream’s process of building, motivating.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="consult ">
        <div className="text-center">
          <h1>Need A Consultation?</h1>
          <a className="btn btn-primary btn-lg" href="/contact">Contact Us</a>
        </div>
      </div>

      <div className="container">
        <div id="clients">
          <Slider {...settings}>
            <div className="mx-1" ><img src={require("../utils/Img/service/client1.png")} alt=''/></div>
            <div className="mx-1" ><img src={client2} alt=''/></div>
            <div className="mx-1" ><img src={client3} alt=""/></div>
            <div className="mx-1" ><img src={client4} alt=""/></div>
            <div className="mx-1" ><img src={client5} alt=""/></div>
            <div className="mx-1" ><img src={client6} alt=""/></div>
          </Slider>
        </div>
      </div>
    </Wrapper>
  )
}


const Wrapper = styled.div `
  .hero{
    height:450px;
    background-image:  url(${require("../utils/Img/service/home-14.jpg")});
    background-repeat: no-repeat;
    background-position:center;
    background-size:cover;
    color:#fff;
  }
  .service_support{
    padding:0 3%;
    position: relative;
    .card{
      padding:15px;
      position: relative;
      top:-75px;
      width:80%;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;

      img{
        width:80px;
        margin:0 auto;
      }
    }
  }
  .icon{
    width:70px;
    height:80px;
  }
  h1{
    font-weight:800;
  }
  .consult{
    padding: 10px;
    color: #fff;
    height: 400px;
    background-image: url(${require("../utils/Img/service/bg-cta-service.jpg")});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .consult .btn {
    padding: 10px 25px;
    width: 190px;
    background: linear-gradient(to right, #b92b27, #1565c0);
    border:none;
    transition: all ease 1s;
  }
  .consult .btn:hover {
    background: linear-gradient(to right, #1565c0, #b92b27);
  }
  .consult div{
    margin-top: 10%;

    @media screen and (max-width:480px){
      margin-top:45%;
    }
  }
  #clients{
    margin:2% auto;

    img{
      width:120px;

      @media screen and (max-width:480px){
        width:80px;
      }
      @media screen and (max-width:340px){
        width:70px;
      }
    }
  }
`;