import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import {Axios} from '../../utils/middleware/config';
import { BeatLoader } from 'react-spinners';
import { AdminLayout } from './AdminLayout';


export const ProjectCategory = () => {

  const [categories, setCategories] = useState([]);
  const {handleSubmit,reset, formState:{errors}} =useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [categoryName,setCategoryName] = useState('');
  const [actionBtn, setActionBtn] = useState('Add')
  const [id, setId] = useState('');
  

  const loadCategory = async () =>{
    const {data } = await Axios.get(`project/category`);
    if( data){
      const {category} = data.data;
      setCategories(category);
    }
  }
  const editCategory = (categoryId) =>{
    console.log(categoryId)
    setId(categoryId);
    console.log(id);
    let cat = categories.find(x => x.id === categoryId);
    setCategoryName(cat.name);
    setActionBtn("Update");
  }
  const deletCategory = async (categoryId) =>{
    const {data} = await Axios.delete(`/project/category/${categoryId}`);
    if(data){
      loadCategory();
    }
  }
 
  const handleSubmitForm = async (formData) =>{
    setIsLoading(true);
    let payload = {name:categoryName}
    if(categoryName ===''){
      setIsLoading(false)
      return;
    }
    console.log(id)
    let updatePayload ={
      id:id,
      name:categoryName
    }
    try {
      const {data} = actionBtn === 'Add'? await Axios.post(`/project/category`,payload) :
      await Axios.put(`/project/category`,updatePayload);
      if(data){
        setTimeout(() => {
          reset();
          setIsLoading(false);
          setCategoryName('');
          setActionBtn("Add");
          loadCategory();
        }, 2000);
        return;
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error)
      setIsLoading(false);
    }
  }
 
  useEffect(() =>{
    loadCategory();
  },[]);

  return (
    <Wrapper>
      <AdminLayout>
        <div className="row gutter-1">
          <div className="col-md-4 col-sm-12">
            <form onSubmit={handleSubmit(handleSubmitForm)} method="post">
              <div className="form-group mb-2">
                <input type="text" onChange={(e)=>setCategoryName(e.target.value)} value={categoryName} name="name" className="form-control" placeholder='Category Name' 
                  // {...register('name',{required:true})}
                />
                {errors.name && <span className='text-danger'>Category Name is required.</span>}
              </div>
              <div className=''>
                {!isLoading && <button className="btn-primary-block">{actionBtn} Category</button>}
                {isLoading && <BeatLoader className='mt-1  loader' color='#ccc' speedMultiplier={0.9} />}
              </div>
            </form>
          </div>
          <div className="col-md-8 col-sm-12 table-div">
            {categories.length > 0?<div>
                <table className='table table-stripped'>
                  <thead className='thead-dark'>
                    <tr>
                      <th>#</th>
                      <th>NAME</th>
                      <th>DATE CREATED</th>
                      <th>LAST MODIFIED</th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      categories.map((category,index) =>{
                        return <tr key={category.id}>
                          <td>{++index}</td>
                          <td>{category.name}</td>
                          <td>{new Date(category.created_at).toLocaleDateString()}</td>
                          <td>{new Date(category.modified_at).toLocaleDateString()}</td>
                          <td>
                            <i className="fas fa-edit fa-lg edit"dataid={category.id}
                              onClick={(e)=>editCategory(e.currentTarget.getAttribute('dataid'))}
                            ></i>
                            <i className="fas fa-trash fa-lg fa-fw delete" dataid={category.id}
                              onClick={(e)=>deletCategory(e.currentTarget.getAttribute("dataid"))}
                            ></i>
                          </td>
                        </tr>
                      })
                    }
                  </tbody>
                </table>
              </div> :
              <><p className="lead mt-2">No Category found!</p>
              </>
            }
          </div>
        </div>
      </AdminLayout>
    </Wrapper>
  )
}

const Wrapper = styled.div `

  form{
    width:90%;
    padding:25px;
    margin:5% auto;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .table-div{
    overflow-x:auto;
  }
  .edit:hover, .delete:hover{
    cursor:pointer;
    transform: scale(1.2);
  }
  .edit{
    color:#3f8df2f1;
    transition:1s ease all;
  }
  .delete{
    color:#f26659f1;
    transition:1s ease all;
  }
`;