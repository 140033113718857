import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {Axios} from '../../utils/middleware/config';
import { BlogPageLayout } from './BlogPagLayout';
import { XpressImage } from '../../utils/helper';
import { MockBlog } from './MockBlog';

export const Blog = () => {

  const [blogItems, setBlogItems] = useState([]);
  const [hasNext, setHasNext] = useState(false);
  const [next, setNext] = useState(0);
  const [hasPrevious, setHasPrevious] = useState(false);
  const [previous, setPrevious] = useState(0);

  const loadBlogs = async(page) => {
    try {
      const {data} = await Axios.get(`/blog?page=${page}`);
      if(data){
        const {blogs, hasNextPage, nextPage, hasPreviousPage, previousPage} = data.data;
      
        setBlogItems(blogs);
        setHasNext(hasNextPage);
        setNext(nextPage);
        setHasPrevious(hasPreviousPage);
        setPrevious(previousPage);
      }
    } catch (error) {
      
    }
    
  }
  const handleNextPage  = () => loadBlogs(next);
  const handlePreviousPage = () => loadBlogs(previous);
 

  useEffect(() => {
    if(blogItems.length === 0){
      loadBlogs();
    }
  },[blogItems])
  return (
    <Wrapper>
      <div className="container-fluid">
        <BlogPageLayout>
         {blogItems.length > 0 ? <>
            <div className="row card-group">
              {
                blogItems.map( (blog) =>{
                  let slug =encodeURIComponent(`${blog.slug}`);
                  return <div key={blog.id} className="col-md-6 col-sm-12 ">
                    <div className="mt-4 blog">
                      <div className="postImg">
                        <XpressImage publicId={blog.image_url} />
                      </div>
                      <div className="category d-flex gap-2">
                        {/* <span>Technology</span> */}
                        <span>{blog.category}</span>
                      </div>
                      <div className="info d-flex gap-3 ms-4">
                        <span className="date">{new Date(blog.created_at).toLocaleString().split(',')[0]}</span>
                        <span><i className="fas fa-comment fa-sm fa-fw"></i> {blog.comments > 0 ? blog.comments : ''} Comments</span>
                        <span className='like'><i className="fas fa-heart  fa-sm fa-fw"></i> {blog.likes > 0 ? blog.likes : ''}</span>
                      </div>
                      <div className="px-4 py-2 position-relative">
                        <h1 className="title">{blog.title}</h1>
                        <p className="post-content">
                          {blog.content}
                        </p>
                        <Link className="more" to={`/blog/article/${slug}`}>
                          <i className="fa-solid fa-circle-arrow-right"></i> <span>Learn More</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                })
              }
            </div>
            
            <div className="pagination">
              {hasPrevious && <button className="prev" onClick={handlePreviousPage}>Previous</button>}
              {hasNext && <button className="next" onClick={handleNextPage}>Next</button>}
            </div>
          </> :
          <div>
            <MockBlog/>
          </div>
          }
        </BlogPageLayout>
        
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div `
  main{
    .blog{
      box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
      min-height: 475px;
      .postImg{
        width:100%;

        img{
          height:95%;
          width:100%;
        }
      }
      .category{
        position:relative;
        top:-12px;
        left:10px;
        span{
          background:#67dafb;
          color:#fff;
          padding:5px 10px;
          font-weight:700;
        }
      }
      .info{
        .like{
          cursor:pointer;
        }
        .fa-heart{
          color:red !important;
        }
      }
      .title{
        font-weight:800;
        font-size:20px;
        line-height:1.2;
        margin-top:5px;

        @media screen and (max-width:480px){
          font-size:18px;
        }
      }
      // show blog post few contents
      .post-content{
        display: -webkit-box;
        overflow : hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical; 

        @media screen and (max-width:480px){
          -webkit-line-clamp: 4;
        }
      }
      .more{
        color:#67dafb;
        font-size:1.2em;
        text-transform:uppercase;
        font-weight:800;
        text-decoration:none;
        position:absolute;
        bottom:-21px;
      }
      .more span{
        margin-left:15px;
        transition: all ease 1s;
      }
      .more:hover span{
        margin-left:4px;
        color:#7bd3fdc4;
      }
    }
    .pagination{
      display:flex;
      gap:18px;
      margin:5% 0;
      width:max-content;
      position:relative;
      float:right;

      .prev,.next{
        border-radius:5px;
        padding:5px 15px;
        font-weight:800;
        font-size:18px;
        background:#3a057efb;
        border:none;
        color:#fff;
        transition:1s all ease;
      }
      .prev:hover,.next:hover{
        background:#2c0a4eb2;
      }
    }
    clear:both;
  }
  aside{
    margin:4% 0;
    .recent-post{
      img{
        width:120px;
      }
    }
    .tags{
      .items{
        display:flex;;
        gap: 9px;
        flex-flow:row wrap;
        margin-bottom:5%;

        span{
          padding:10px;
          font-weight:600;
          background:#706e8287;
          color:#fff;
          transition:1s all ease;
        }
        span:hover{
          background:#211E3B;
          border-radius:5px;
          cursor:pointer;
        }
      }
    }
  }
`;