import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {useForm} from 'react-hook-form'
import {Axios} from '../../utils/middleware/config';
import { BeatLoader } from 'react-spinners';


export const BlogPostComment = ({blogId}) => {

  const [comments, setComments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [commentId, setCommentId] = useState('');
  const {register,handleSubmit,reset, formState:{errors}} = useForm({
    defaultValues:{
      name: "",
      email:'',
      comment:''
    }
  });

  const loadComments = async (id) =>{
    try {
      const {data} = await Axios.get(`/blog/${id}/comments`);
      if(data){
        // console.log(data);
        setComments(data.data);
      }
    } catch (error) {
      
    }
  }

  const handlePostComment = async(details) => {
    setIsLoading(true);
    const payload ={
      "fullname":details.name,
      "email":details.email,
      "post_id":blogId,
      "userComment":details.comment
    }
    try {
      //send a reply to a comment
      if(commentId !==""){
        // console.log(commentId);
        const reply={
          "fullname":details.name,
          "email":details.email,
          "userComment":details.comment
        }
        const {data} = await Axios.post(`/blog/${blogId}/comment/${commentId}`,reply);
        if(data){
          console.log(data.data);
          loadComments(blogId);
          setTimeout(() => {
            setIsLoading(false);
            setCommentId("");
            reset();
            return;
          }, 3000);
        }
        else{
          setIsLoading(false);
          setCommentId("")
          reset();
        }
        return;
      }
      //comment on a blog post
      const {data} = await Axios.post(`/blog/${blogId}/comment`,payload);
      if(data){
        console.log(data.data);
        loadComments(blogId);
        setTimeout(() => {
          setIsLoading(false);
          reset();
          return;
        }, 3000);
      }
      else{
        setIsLoading(false);
        reset();
      }
    } catch (error) {
      
    }
   
  }

  const likeComment = async(id) => {
    try {
      const {data} = await Axios.put(`/blog/${blogId}/comments/${id}/like`);
      if(data){
        loadComments(blogId);
      }
    } catch (error) {
      
    }
  }

  const disLikeComment = async(id) => {
    try {
      const {data} = await Axios.put(`/blog/${blogId}/comment/${id}/dislike`);
      if(data){
        loadComments(blogId);
      }
    } catch (error) {
      
    }
  }

  useEffect(() =>{
    if(comments.length === 0){
      loadComments(blogId)
    }
  },[comments, blogId])

  return (
    <CommentWrap>
      <div className="comments">
        <div>
          <h3><span>{comments.totalBlogPostComment > 0 ? comments.totalBlogPostComment : ''}</span> Comments</h3>
          <div>
            {comments.blogPostComment && comments.blogPostComment.map(userComment =>{
              return<div key={userComment.id} className='mb-3'>
                  <h6>{userComment.name} <span className="text-muted fst-italic ms-5">{new Date(userComment.created_at).toDateString()}</span></h6>
                  <p>{userComment.comment}</p>
                  {userComment.reply && <div className="replies ms-5">
                    <h5>Comment Reply</h5>
                    {
                      userComment.reply.map((rep,index) =>{
                        return<div key={index} className='mb-3'>
                          <h6>{rep.fullname} <span className="text-muted fst-italic ms-5">{new Date(rep.date).toDateString()}</span></h6>
                          <p>{rep.userComment}</p>
                        </div>
                      })
                    }
                  </div>}
                  <span className='my-2'onClick={()=> setCommentId(userComment.id)}><a href="#feedback">Reply</a></span>
                  <div className="d-flex align-items-center gap-4 mt-3">
                    <span className='user-reaction' onClick={() => likeComment(userComment.id)}>{userComment.likes > 0 ? userComment.likes : ''}<i className="fa fa-thumbs-up mx-1" aria-hidden="true"></i>Like</span>
                    <span className='user-reaction' onClick={() => disLikeComment(userComment.id)}>{userComment.dislikes > 0 ? userComment.dislikes : ''}<i className="fa fa-thumbs-down mx-1" aria-hidden="true"></i>Dislike</span>
                  </div>
                </div>
              })
            }
          </div>
        </div>
      </div>
      <div className="cover my-5 container-fluid">
        <div className="feedback " id='feedback'>
          <h4 className='mb-0'>Leave a Comment</h4>
          <p className="mt-0 text-danger">* your email will not be published.</p>
          <form onSubmit={handleSubmit(handlePostComment)}>
            <div className="form-group">
              <textarea name="comment" placeholder='Your Comment' cols="38" rows="5"
                {...register('comment',{required:true})}
              ></textarea>
              {errors.comment && <span className="text-danger">Comment is required</span>}
            </div>
            <div className="row my-2">
              <div className="col-md-6 col-sm-12 mt-2 ">
                <input type="text" name="name" id="" className="form-control" placeholder='FullName' 
                  {...register('name',{required:true})}
                />
                {errors.name && <span className="text-danger">Full Name is required.</span>}
              </div>
              <div className="col-md-6 col-sm-12 mt-2">
                <input type="email" name="email" placeholder='Email Address' className="form-control" 
                  {...register('email',{required:true})}
                />
                {errors.email && <span className="text-danger">Email is required.</span>}
              </div>
            </div>
          {!isLoading ? <button className=" btn-comment">Post Comment</button> :
            <BeatLoader color='#ccc' className='mt-2 text-center btn-load' speedMultiplier={0.9} />}
            
          </form>
        </div>
      </div>
    </CommentWrap>
  )
}

const CommentWrap = styled.div `
  padding:0 4%;
  .user-reaction{
    i{
      font-weight: 700;
      font-size: 1.1em;
    }
    i.fa-thumbs-up{
      color:#046202cc;
    }
    i.fa-thumbs-down{
      color:#aa0303;
    }
    cursor:pointer;
  }
  .cover{
    padding:5%;
    background:rgba(149, 157, 165, 0.2);
  }
  .feedback{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 18px 44px;
    border-radius:5px;
    padding:15px;
    width:99%;  
   
    textarea{
      width:100%;
      border:none;
      border-radius:5px;
      padding:3px;
    }
    textarea:focus, input:focus{
      outline:none !important;
    }
    textarea, input{
      border:none;
      padding:5px;
    }
    .btn-comment{
      border-radius:5px;
      border:none;
      padding:5px 15px;
      color:#fff;
      background:#91A5F9;
      font-weight:800;
      transition:0.5s all ease;
    }
    .btn-comment:hover{
      border:0.5px solid #91A5F9;
      background:#fff;
      color:#91A5F9;
    }
    .btn-load{
      width: 160px;
      height:40px;
      border-radius:5px;
      border:none;
      padding:5px 15px;
      background:#91A5F9;
      font-weight:800;
    }
  }
`;