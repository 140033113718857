import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Home from './pages/Home';
import Career from './pages/Career';
import { NotFound } from './pages/NotFound';
import { Service } from './pages/Service';
import { Research } from './pages/Research';
import { About } from './pages/About';
import { Contact } from './pages/Contact';
import { TeamRegistration } from './pages/TeamRegistration';
import { Login } from './components/adminComponent/Login';
import { ResetPassword } from "./components/adminComponent/ResetPassword";
import { Dashboard } from './pages/Dashboard';
import { AdminHome } from "./components/adminComponent/AdminHome";
import { Projects } from "./components/adminComponent/Projects";
import { Order } from "./components/adminComponent/Order";
import { AddProject } from "./components/adminComponent/AddProject";
import { PublicPages } from "./pages/PublicPages";
import { BlogPage } from "./pages/BlogPage";
import { BlogDetailsPage } from "./pages/BlogDetailsPage";
import { ProjectDetails } from "./components/researchComponents/ProjectDetails";
import { Cart } from "./components/researchComponents/Cart";
import { NewBlogPost } from "./components/adminComponent/blogComponents/NewBlogPost";
import { ProjectCategory } from "./components/adminComponent/ProjectCategory";
import { Courses } from "./components/adminComponent/Courses";
import { Author } from "./components/adminComponent/blogComponents/Author";
import { BlogCategory } from "./components/adminComponent/blogComponents/BlogCategory";

function App() {

  useEffect(() => {
    
  }, []);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route element={<PublicPages/>}>
            <Route path='/' exact element={<Home />}/>
            <Route path='/home' exact element={<Home />}/>
            <Route path='/career' exact element={<Career />} />
            <Route path='/talent' exact element={<TeamRegistration/>}/>
            <Route path='/services' exact element={<Service />} />
            <Route path='/research' exact element={<Research />} />
            <Route path='/research/project/:id' exact element={<ProjectDetails/>} />
            <Route path='/research/project/:id/cart' exact element={<Cart/>} />
            <Route path='/about' exact element={<About />}/>
            <Route path='/contact' exact element={<Contact />} />
            <Route path="/blog" exact element={<BlogPage/>} />
            <Route path="/blog/article/:slug" exact element={<BlogDetailsPage/>} />
          </Route>

          <Route path='/login' exact element={<Login/> } />
          <Route path="/reset-password" exact element={<ResetPassword/>} />
          
          <Route element={<Dashboard />}>
            <Route path="admin/dashboard" exact element={<AdminHome />}/>
            <Route path="admin/dashboard/home" exact element={<AdminHome/>} />
            <Route path="admin/dashboard/projects" exact element = {<Projects/>} />
            <Route path="admin/dashboard/orders" exact element ={<Order />} />
            <Route path="admin/dashboard/add-project" exact element={<AddProject/>}/>
            <Route path="admin/dashboard/create-post" exact element={<NewBlogPost/>} />
            <Route path="admin/dashboard/project/category" exact element={<ProjectCategory/>}/>
            <Route path="admin/dashboard/courses" exact element={<Courses/>}/>
            <Route path="admin/dashboard/blog/author" exact element={<Author/>}/>
            <Route path="admin/dashboard/blog/category" exact element={<BlogCategory/>} />
          </Route>

          <Route path="/404" exact element={<NotFound />}/>
          <Route path="*" element={<Navigate to="/404" replace />} /> 
        </Routes>
        
      </Router>
    </div>
  );
}

export default App;
