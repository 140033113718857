import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import {BeatLoader} from "react-spinners"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Axios} from '../../utils/middleware/config';



export const JoinTeam = () => {

  const [isLoading, setIsLoading] = useState(false);
  const {register,handleSubmit, formState:{errors}, reset} = useForm();

  const handleForm = async(data) =>{
    setIsLoading(true);
    const payload = {
      fullName: data.name,
      email: data.email,
      mobile: data.phoneNumber,
      yearsOfExperience: data.experienceLevel,
      portfolioLink: data.portfolioLink
    }
    try {
      const result = await Axios.post('/talent',payload);
      if(result.data.data){
        setTimeout(() => {
          toast.success('Application was Successful.', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            theme: "colored",
          });
          reset();
          setIsLoading(false);
        }, 3000);
      }
    } catch (error) {
      let {message} = error.response.data;
      const validationError =  error.response.data.data ? error.response.data.data[0] : null;

      setTimeout(() => {
        setIsLoading(false);

        if(validationError){
          toast.error( validationError.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            theme: "colored",
          });
          setIsLoading(false);
          return;
        }
        toast.error( message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: "colored",
        });
        reset();
        setIsLoading(false);
      }, 3000);
    }
  }

  return (
    <TeamWrap>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-5 col-sm-12">
            <img className="img-fluid poolImage" src={require("../../utils/Img/career/talentpool.jpeg")} alt=""/>
          </div>
          <div className="col-md-7 col-sm-12">
            <div className="m-3 details">
              <h4>Join our excellent and dedicated team</h4>
              <form onSubmit={handleSubmit(handleForm)}>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group my-2">
                      <input type="text" name='name' className="form-control" placeholder="Full Name"
                        {...register("name",{required:true})}
                      />
                      {errors.fullname && <span className='text-danger'>Full Name is required.</span>}
                    </div>
                    <div className="form-group my-2">
                      <input type="email" name="email" className="form-control" placeholder="Email Address" 
                        {...register("email",{required:true})}
                      />
                      {errors.email && <span className='text-danger'>Email is required.</span>}
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group my-2">
                      <input type="text" name="phoneNumber" className="form-control" placeholder="Phone Number"
                        {...register("phoneNumber",{required:true})}
                      />
                      {errors.phoneNumber && <span className='text-danger'>Phone Number is required.</span>}
                    </div>
                    <div className="form-group my-2">
                      <input type="number" name="experienceLevel" className="form-control" placeholder="Years of Experience" 
                        {...register("experienceLevel",{required:true})}
                      />
                      {errors.experienceLevel && <span className='text-danger'>Experience Level is required.</span>}
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group my-2">
                      <input name='portfolio-link'  className="form-control" placeholder="Portfolio link" 
                        {...register("portfolioLink",{required:true})}
                      />
                      {errors.portfolioLink && <span className='text-danger'>Portfolio Link is required</span>}
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group my-2">
                      {!isLoading ? <button className="btn font-weight-bolder btn-block">Join Team</button> :
                        <BeatLoader color='#ccc' className='text-center my-3 loader btn' speedMultiplier={0.9} />
                      }
                    </div>
                  </div>
                </div>
                <ToastContainer />
              </form>
            </div>
          </div>
        </div>
      </div>
      
    </TeamWrap>
  )
}

const TeamWrap = styled.div `
  margin:5px 0;
  .poolImage{
    height:100%;
    width:auto;
  }
  .details{
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    border-radius:5px;
    padding:10px;
    margin-top:7% !important;

    @media screen and (max-width:768px){
      padding:5px;

      h4{
        font-size:18px;
      }
    }
    @media screen and (max-width:380px){
      h4{
        font-size:14px;
        font-weight:bold;
        text-align:center;
      }
    }
    form{
      padding:5px;

      .btn, .loader{
        background:#AABAFA;
        color:#fff;
        width:100%;
        font-weight:800;
        transition:1s ease all;
      }
      button:hover{
        background:#211E3B;
      }
      .loader{
        background:#211E3B !important;
      }
    }
  }
`;