import React,{useEffect, useState} from 'react';
import styled from 'styled-components';
import {Axios} from '../../utils/middleware/config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AdminLayout } from './AdminLayout';
import { XpressImage } from '../../utils/helper';

export const Projects = () => {

  const [projects, setProject] = useState([]);
  const [nextPage, setNextPage] = useState();
  const [hasNext, setHasNext] = useState(false);
  const [previousPage, setPreviousPage] = useState();
  const [hasPrevious, setHasPreviousPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  
  const loadProject= async(page) =>{
    const {data} =await Axios.get(`/projects?page=${page}`);
    if(data){
      console.log(data.data);
      const {projects, hasNextPage, nextPage, hasPreviousPage, previousPage} = data.data;
      setProject(projects);
      setHasNext(hasNextPage);
      setNextPage(nextPage);
      setPreviousPage(previousPage);
      setHasPreviousPage(hasPreviousPage);
    }
  }

  const handleNextPage = () => {setCurrentPage(nextPage);loadProject(nextPage)};
  const handlePreviousPage = () => {setCurrentPage(previousPage);loadProject(previousPage)};
  
  const handleDeleteProject = async(id) => {
   const {data} = await Axios.delete(`/project/${id}`);
   if(data){
    console.log(data.data);
    console.log('deleted Id',id);
    setTimeout(() => {
      toast.success("project Deleted Successfully",{
        position:'top-right',
        hideProgressBar:false,
        pauseOnHover:true
      });
      loadProject(currentPage);
    }, 1000);
    
   }
  }
  
  useEffect(()=>{
    if(currentPage === 0){
      loadProject();
    }
   
  },[currentPage])
  return (
    <Wrapper>
      <AdminLayout>
        {projects.length > 0?
          <div className="row container-fluid p-2">
            {projects.map((project) =>{
              return<div className='col-md-6 col-sm-12' key={project.id}>
                <div className="proj mb-4">
                  <XpressImage publicId={project.image_url} />
                  <div className='text-wrap'>
                    <h4>{project.title}</h4>
                    <p>{project.description}</p>
                    <span className='me-3'>{project.category}</span>
                    <span>{parseInt(project.price).toLocaleString()}</span>
                    <div className='actions'>
                      <i className="fas fa-edit fa-lg me-2 edit" ></i>
                      <i data-key={project.id} className="fa fa-trash fa-lg ms-2 delete" aria-hidden="true" onClick={(e)=>handleDeleteProject(e.currentTarget.getAttribute("data-key"))}></i>
                    </div>
                  </div>
                </div>
              </div>
              }) 
            }
            <ToastContainer/>
            <div className="pagination">
              {!hasPrevious && <button className="prev me-3" onClick={handlePreviousPage}>Previous</button>}
              {hasNext && <button className="next ms-1" onClick={handleNextPage}>Next</button>}
            </div>
          </div> :
          <p className='text-lead my-5'>No Data...</p>
        }
      </AdminLayout>
    </Wrapper>
  )
}

const Wrapper = styled.div `
  
  .proj{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-bottom-left-radius:5px;
    border-bottom-right-radius:5px;

    img{
      width:100%;
    }
    .text-wrap{
      padding:15px;
    }
  }
 
 
  }
`;