import React from 'react';
import styled from 'styled-components';
import { CloudinaryImageUrl } from '../../utils/helper';


export const BlogAuthor = ({blogAuthor}) => {

  let details = blogAuthor.bio;
  return (
    
    <Wrapper>
      <div className="d-flex gap-4 align-items-center">
        <div className="author-pix">
          {details &&<img className="img-fluid" width={100} src={CloudinaryImageUrl(details.avatar_url)} alt="Blog Author" />}
        </div>
        <div className="author-info">
          <h4>Author</h4>
          <h6 className='mb-0'>{blogAuthor.author}</h6>
          {details && <><p className='mt-0'>{details.about }</p>
          <div className="d-flex">
            <i className="fab fa-facebook fa-md fa-fw"></i>
            <i className="fab fa-twitter fa-md fa-fw"></i>
            <i className="fab fa-linkedin fa-md fa-fw"></i>
            <i className="fab fa-github fa-md fa-fw"></i>
          </div></>}
        </div>
      </div>
      <hr />
    </Wrapper>
  )
}

const Wrapper = styled.div `
  .author-pix{
    img{
      width:120px;
      height:120px;
      border-radius:50%;
    }
  }
  .author-info{
    h4{
      color:#91A5F9;
      font-weight:800;
    }
    h6{
      font-weight:700;
    }
    i{
      cursor:pointer;
      transition:1s ease all;
    }
    i:hover{
      transform:scale(1.4);
    }
  }
`;