import React from 'react';
import styled from 'styled-components';
import { Blog } from '../components/blog/Blog';


export const BlogPage = () => {

  
  return (
    <Wrapper>
      <Blog/> 
    </Wrapper>
  )
}

const Wrapper = styled.div `
 
  
`;