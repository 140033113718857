import React from 'react';
import styled from 'styled-components';


export const Hero = () => {
  return (
    <Wrapper>
      <div id="career_hero_section" >
        <div className="row">
          <div className="col-md-4 col-sm-12">
            <div className='img-div'>
              <img className="img-fluid " src={require("../utils/Img/career/career_hero.png" )}alt='' />
            </div> 
          </div>
          <div className="col-md-8 col-sm-12">
            <div className="txt">
              <h1>Learn high-income skills</h1>
              <p>Whether you're new to the world of technology or looking to expand your knowledge, our platform offers comprehensive resources to help you succeed.</p>
              <p>
                We promote lifelong micro-learning through our cohort program, and our 
                team remains committed to providing more technical training using diverse channels 
                & methods to scale digital education to millions more at a lower cost.
              </p>
              <a href="#tutorial" className="btn btn-lg  btn_start">Get Started</a>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div `
  #career_hero_section {
    background: linear-gradient(90deg, rgba(244,225,255,0.9897001036742822) 0%, rgba(196,159,227,1) 42%, rgba(254,248,216,1) 85%);
    padding:1% 5% 6% 6%;

    .img-div{
      max-height:380px;
    }
    img{
      width:95%;
    }

    @media screen and (max-width:480px){
      padding:3%;
    }
  }
  #career_hero_section .txt{
    margin-top:20px;
    font-weight: 400;
    font-size:18px;
  }
  #career_hero_section .btn_start{
    margin-top: 25px;
    padding:10px 35px;
    
    font-weight:900;
    background-color:#62029f;
    color:#fff;
    border:1.5px solid #62029f;
    
    transition: all ease 1s;
  }
  #career_hero_section .btn_start:hover{
    background-color:transparent;
    color:#62029f;
    border:1.8px solid #fff;
  }

  @media screen and (max-width:768px) {
    #career_hero_section .txt{
      margin-top:10%;
    }
  }
`;