import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {useForm} from "react-hook-form";
import {Axios} from '../../utils/middleware/config';
import { ToastContainer, toast } from 'react-toastify';
import { BeatLoader } from 'react-spinners';
import 'react-toastify/dist/ReactToastify.css';

export const HireWriterForm = ({closeBtn}) => {

  const {register, handleSubmit, formState:{errors}, reset} = useForm();
  const [projectCategory, setProjectCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  const loadData = async() => {
    try {
      const {data} = await Axios.get('/project/category');
      if(data.success){
        setProjectCategory(data.data.category);
      }
    } catch (error) {
      
    }
  };

  const handleRequests = async(data) =>{
    setIsLoading(true);
    const payload = {
      fullName: data.fullName,
      email: data.email,
      mobile: data.phoneNumber,
      projectTitle: data.topic,
      categoryId: data.category,
      description: data.description
    }
    try {
      const {data} = await Axios.post('/project/request', payload);
      if(data.success){
       setTimeout(() => {
        toast.success('Topic Submitted', {
          position: "top-right",
          autoClose: 900,
          theme: "colored",
        });
        setIsLoading(false);
       }, 2000);
       setTimeout(() => {
        reset();
        document.getElementById(closeBtn).click();
       }, 4000);
      }
    } catch (error) {
      const {data} = error.response;
      if(!data.success){
        setTimeout(() => {
          toast.error('Error Submitting your request', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            draggable:false,
            pauseOnHover: true,
            progress: undefined,
            theme: "colored",
          });
          setIsLoading(false);
        }, 2000);
        
      }
    }
  }

  useEffect(() =>{
    if(projectCategory.length < 1){
      loadData()
    }
  },[projectCategory])

  return (
    <Wrapper>
      <div className="container-fluid">
        <div className="m-3">
          <form onSubmit={handleSubmit(handleRequests)}>
            <h6 className="mb-0">Submit Thesis Topic</h6>
            <p className="mt-0">Get your project delivered within the shortest time frame.</p>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="form-group my-2">
                  <input name="fullName" className="form-control" placeholder="Full Name"
                    {...register("fullName",{required:true})}
                  />
                  {errors.fullName && <span className="text-danger">Full Name is required.</span>}
                </div>
                <div className="form-group my-2">
                  <input name="email" className="form-control" placeholder="Email Address"
                    {...register("email",{required:true})}
                  />
                  {errors.email && <span className="text-danger">Email is required.</span>}
                </div>
                <div className="form-group my-2">
                  <input name="phoneNumber" className="form-control" placeholder="Phone Number"
                    {...register("phoneNumber",{required:true})}
                  />
                  {errors.phoneNumber && <span className="text-danger">Phone Number is required.</span>}
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="form-group my-2 ">
                  <input name="topic" className="form-control"  placeholder='Project Topic'
                    {...register("topic",{required:true})}
                  />
                  {errors.topic && <span className="text-danger">Project Topic is required.</span>}
                </div>
                <div className="form-group my-2">
                  <select name="category" className="form-control"
                    {...register('category',{required:true})}
                  >
                    <option value=''>Select Project Category</option>
                    {projectCategory.map((category) => {
                      return <option key={category.id} value={category.id}>{category.name}</option>})
                    }
                  </select>
                  {errors.category && <span className="text-danger">Project category is required.</span>}
                </div>
                <div className="form-group my-2">
                  <textarea name="description" cols="3" rows="1" className="form-control" placeholder="Short Description" 
                    {...register('description',{required:true})}
                  ></textarea>
                  {errors.description && <span className="text-danger">Project description is required.</span>}
                </div>
              </div>
              
              <div className="col-md-12 col-sm-12">
                <div className="form-group">
                 {!isLoading ? <button className="btn">SEND</button> :
                  <BeatLoader color='#ccc' className='btn ' speedMultiplier={0.9} />
                  }
                </div>
              </div>
            </div>
          </form>
          <ToastContainer/>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div `
  form {
    border-radius: 5px;
    padding: 20px;
    width: 87%;
    margin: 0 auto;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;

    button,.btn{
      background:#5c3f7a;
      color:#fff;
      font-weight:800;
      width:100%;
      transition:ease 1s;
    }
    button:hover{
      background:#6274fb;
      color:#fff;
    }
  }
`;