import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {useForm } from "react-hook-form";
// import {Axios} from '../../utils/middleware/config';
import { BeatLoader } from 'react-spinners';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const HireRequest = () => {

  const [isLoading, setIsLoading] = useState(false);
  const {register,handleSubmit,reset, formState:{errors}} = useForm({
    defaultValues:{
      fullName:'',
      email:'',
      phone:'',
      title:'',
      description:''
    }
  });

  const onSubmitForm = async (formData) =>{
    setIsLoading(true);
    // const payload ={
    //   fullName:formData.fullName,
    //   email: formData.email,
    //   phone: formData.phone,
    //   title: formData.title,
    //   description: formData.description
    // }
    try {
      // const {data } = await Axios.post(``,payload)
      // if(data){
      //   console.log(data.data)
  
      //   toast.success('Request Sent ', {
      //     position: "top-right",
      //     autoClose: 1000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     progress: undefined,
      //     theme: "colored",
      //   });
      //   setTimeout(() => { 
      //     reset();
      //     setIsLoading(false);
      //     document.getElementById('closeModal').click();
      //   }, 4000);
      //   return;
      // }

      toast.error('Not Sent! Use contact section ', {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
        theme: "colored",
      });
      setIsLoading(false);
    } catch (error) {
      const {data} = error.response;
      const msg = data.data ? data.data[0].message : data.message;

      setTimeout(() => {
        toast.error(msg, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: "colored",
        });
        setIsLoading(false)
        reset();
      }, 3000);
    }
   
  }
  useEffect(() =>{

  },[])
  
  return (
    <Wrapper>
      <div className="container">
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="form-group my-2">
                <input type="text" name="fullName" placeholder='Full Name' className="form-control" 
                  {...register('fullName',{required:true})}
                />
                {errors.fullName && <span className='text-danger'>Full Name is required.</span>}
              </div>
              <div className="form-group my-2">
                <input type="email" name="email" placeholder='Email Address' className="form-control"
                  {...register('email',{required:true})}
                />
                {errors.email && <span className='text-danger'>Email is required.</span>}
              </div>
              <div className="form-group my-2">
                <input type="text" name="phone" placeholder='Phone Number' className="form-control" 
                  {...register('phone',{required:true})}
                />
                {errors.phone && <span className='text-danger'>Phone Number is required.</span>}
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="form-group my-2">
                <input type="text" name="title" placeholder='Project Title' className="form-control" 
                  {...register('title',{required:true})}
                />
                {errors.title && <span className='text-danger'>Project Title is required.</span>}
              </div>
              <div className="form-group my-2">
                <textarea name="description" placeholder='Description' cols="3" rows="3" className="form-control"
                  {...register('description',{required:true})}
                ></textarea>
                {errors.description && <span className='text-danger'>Short description is required</span>}
              </div>
            </div>
            {!isLoading ? <button className="btn btn-hire">Hire Now</button> :
            <BeatLoader color='#ccc'  className='loader my-3' cssOverride={{textAlign:'center'}} speedMultiplier={0.9} />}
          </div>
        </form>
        <ToastContainer/>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div `
  form{
    padding:15px;
    margin:5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    .btn-hire{
      background:#4c5599;
      color:#fff;
      width:97%;
      margin: 0 auto; 
      font-weight:800;
    }
    .btn-hire:hover{
      background:#6274fb;
    }
    .loader{
      display:inline !important;
      background:#4c5599;
      padding:5px;
      border-radius:5px;
      width:97%;
      margin:0 auto;
    }
  }
`;
