import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {Axios, AxiosForm}from '../../../utils/middleware/config';
import { useForm } from 'react-hook-form';
import BeatLoader  from "react-spinners/BeatLoader"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AdminLayout } from '../AdminLayout';
import { XpressImage } from '../../../utils/helper';



export const NewBlogPost = () => {

  const {register,handleSubmit,reset, formState:{errors}} = useForm();
  const [blogImg, setBlogImg] = useState(null);
  const [categories, setCategories] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [blogPosts, setBlogPosts] = useState([]);
  const [blogPostsDetails, setBlogPostsDetails] = useState({
    hasNext:false,
    hasPrevious:false,
    next:null,
    previous:null
  })
  const [isLoading, setIsLoading] = useState(false);
 

  const loadCategories = async() => {
    const {data} = await Axios.get(`/blog/category`);
    if(data){
      const {category} = data.data;
      setCategories(category);
    }
  }
  const loadBlogPosts = async(page) => {
    const {data} = await Axios.get(`blog?page=${page}`);
    if(data){
      const {blogs,hasNextPage, nextPage, hasPreviousPage, previousPage} = data.data;
      setBlogPosts(blogs);
      setBlogPostsDetails({hasNext:hasNextPage,hasPrevious:hasPreviousPage,next:nextPage, previous:previousPage});
    }
  }
  const loadAuthors = async() => {
    const {data} = await Axios.get(`/blog/authors`);
    if(data){
      const {BlogAuthor} = data.data;
      setAuthors(BlogAuthor);
    }
  }

  const handleUpload = async (e) =>{
    setBlogImg((e.target.files[0]));
  }
  const uploadImage = () => document.getElementById('image').click();

  const publishBlogPost = async(postData) => {
    setIsLoading(true);
    const payload = new FormData();
     
    payload.append('title',postData.title);
    payload.append('slug',postData.title.replace(/\s/g,"-"));
    payload.append('content',postData.content);
    payload.append('author_id',postData.author);
    payload.append('category_id',postData.category);
    payload.append('image',blogImg);

    try {
      
      const {data} = await AxiosForm.post(`/blog`,payload);
      if(data){
        console.log(data.data);
        toast.success("Post Published",{
          position:"top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          setIsLoading(false);
          reset();
          setBlogImg(null);
          document.getElementById('blog-post-tab').click();
        }, 3000);
      }else{setIsLoading(false)}
    } catch (error) {
      let {message} = error.response.data;
      message =  error.response.data.data ? error.response.data.data[0].message : message;
      console.log(message);
      setTimeout(() => {
        setIsLoading(false);
        toast.error( message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: "colored",
        });
        
      }, 2000);
    }
   
  }

  const editPost = async () =>{

  }
  const deletePost = async () =>{
    
  }

  useEffect(() =>{
    if(authors.length === 0 || categories.length === 0){
      loadAuthors();
      loadCategories();
    }
    if(blogPosts.length === 0){
      loadBlogPosts();
    }
  },[authors, categories,blogPosts]);

  return (
    <Wrapper>
      <AdminLayout>
        
        <nav className='ms-4'>
          <div className="nav tabs" id="nav-tab" role="tablist">
            <button className="nav-link active" id="create-post-tab" data-bs-toggle="tab" data-bs-target="#create-post" type="button" role="tab" aria-controls="create-post" aria-selected="true">Create New Post</button>
            <button className="nav-link" id="blog-post-tab" data-bs-toggle="tab" data-bs-target="#blog-post" type="button" role="tab" aria-controls="blog-post" aria-selected="false">Blog Posts</button>
          </div>
        </nav>
        <div className="tab-content" id="nav-tabContent">
          <div className="tab-pane fade show active" id="create-post" role="tabpanel" aria-labelledby="create-post-tab" tabIndex="0">
            <form onSubmit={handleSubmit(publishBlogPost)}>
              <div className='row no-gutters wrap'>
                <div className="col-md-7">
                  <div className="">
                    <div className="form-group mb-2">
                      <input type="text" name="title" placeholder='Blog Title' className="form-control" 
                        {...register('title',{required:true})}
                      />
                      {errors.title && <span className="text-danger">Blog Title is required.</span>}
                    </div>
                    {/* <div className="form-group mb-2 d-none">
                      <input value={title.replace(/\s/g,"-")} onChange={title.replace(/\s/g,"-")} type="text" name="slug" placeholder='Blog Slug' className="form-control" />
                    </div>
                      */}
                    <div className="form-group mb-2">
                      <textarea className='form-control' name="content" placeholder='Blog Content' rows="6"
                        {...register('content',{required:true})}
                      ></textarea>
                      {errors.content && <span className='text-danger'>Blog Content is required.</span>}
                    </div>
                    <div className='d-flex justify-content-between align-items-center'>
                      <div className="form-group mb-2">
                        <select name="category" className="form-control"
                          {...register('category',{required:true})}
                        >
                          <option value="">Select Category</option>
                          {categories.map((category) =>
                            { return <option value={category.id} key={category.id}>{category.name}</option>})
                          }
                        </select>
                        {errors.category && <span className='text-danger'>Blog Category is required.</span>}
                      </div>
                      <div className="form-group mb-2">
                        <select name="author" className="form-control"
                          {...register('author',{required:true})}
                        >
                          <option value="">Select Author</option>
                          {authors.map((author) =>
                            { return <option value={author.id} key={author.id}>{author.name}</option>})
                          }
                        </select>
                        {errors.author && <span className='text-danger'>Blog Author is required.</span>}
                      </div>
                      <div>
                        <input onChange={(e)=>handleUpload(e)} className='d-none' type="file" name="postImage" accept="image/*" id="image" />
                        <i onClick={uploadImage} className="fas fa-image fa-2x fa-fw uploadImageIcon"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <img src={blogImg === null? " ":URL.createObjectURL(blogImg)} alt="" className="img-fluid d-flex w-100" />
                  {blogImg !== null && !isLoading && <button className="btn-primary-block mt-3" type='submit'>Publish Post</button>}
                  {isLoading && <BeatLoader color='#ccc' className='loader mt-3 text-center' speedMultiplier={0.8}/>}
                </div>
              </div>
              <ToastContainer/>
            </form>
          </div>
          <div className="tab-pane fade" id="blog-post" role="tabpanel" aria-labelledby="blog-post-tab" tabIndex="0">
            <div className="row container-fluid my-4">
              { 
                blogPosts.map(post =>{
                  console.log(blogPostsDetails,'details')
                  return <div className="col-md-6 col-sm-12" key={post.id}>
                    <div className="post-item">
                      <XpressImage publicId={post.image_url} className='d-block w-100 px-0'/>
                      <div className="ms-2">
                        <h5><a href="/">{post.title}</a></h5>
                        <div className="actions">
                          <i className="fas fa-edit fa-lg edit"dataid={post.id}
                            onClick={(e)=>editPost(e.currentTarget.getAttribute('dataid'))}
                          ></i>
                          <i className="fas fa-trash fa-lg delete"dataid={post.id}
                            onClick={(e)=>deletePost(e.currentTarget.getAttribute('dataid'))}
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                })
              }
              
            </div>
            <div className="pagination">
              {blogPostsDetails.hasPrevious === true && <button className="prev" onClick={()=>loadBlogPosts(blogPostsDetails.previous)}>Previous</button>}
              {blogPostsDetails.hasNext === true && <button className="next" onClick={()=>loadBlogPosts(blogPostsDetails.next)}>Next</button>}
            </div>
          </div>
        </div>
      </AdminLayout>
    </Wrapper>
  )
}

const Wrapper = styled.div `
  .tabs{

    .nav-link{
      border:none;
      margin: 0 5px;
      background:inherit;
      font-weight:bold;
      padding:0;
    }
    .active{
      background:#91a5f9;
      color:#fff;
      padding:3px 10px;
      border-radius:5px;
    }
  }
  .post-item{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-bottom:4%;
    img{
      width:100%;
    }
    
    a{
      color:#91a5f9;
      text-decoration:none;
    }
  }
  .wrap{
    margin:3% auto 5% auto;
    width:70%;

    @media screen and (width:768px){
      width:78%;
    }
    @media screen and (max-width:600px){
      width:100%;
    }

    .uploadImageIcon{
      cursor:pointer;
      color: #55076a;
    }
  }
`;