import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import {BeatLoader} from "react-spinners"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Axios }from '../utils/middleware/config';

export const ContactUsForm = () => {

  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const sendData = async(data) =>{
    setIsLoading(true);
    const payload = {
      fullName: data.fullName,
      email: data.email,
      mobile: data.phone,
      message: data.purpose
    }

    try {
      const result = await Axios.post('/contact',payload);
      if(result.data.data){
        setTimeout(() => {
          toast.success('Message Sent!.', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            theme: "colored",
          });
          reset();
          setIsLoading(false);
        }, 3000);
      }
    } catch (error) {
      let {message} = error.response.data;
      const validationError =  error.response.data.data ? error.response.data.data[0] : null;

      setTimeout(() => {
        if(validationError){
          toast.error( validationError.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            theme: "colored",
          });
          setIsLoading(false);
          return;
        }
        toast.error( message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: "colored",
        });
        reset();
        setIsLoading(false);
      }, 3000);
    }
   
  }

  return (
    <Wrapper>
      <div id="contact_mail">
        <form onSubmit={handleSubmit(sendData)}>
          <h5>Ready to Get Started?</h5>
          <p>Your email address will not be published. </p>
          <div className="form-group my-1">
            <input name="fullName" className="form-control" type="text" placeholder="FullName " 
              {...register('fullName',{required:true})}
            />
            {errors.fullName && <span className="text-danger">Full Name is required.</span>}
          </div>
          <div className="form-group my-1">
            <input name="phone" className="form-control" type="tel" placeholder="Phone Number "
              {...register("phone",{required:true})}
            />
            {errors.phone && <span className="text-danger">Phone Number is required.</span>}
          </div>
          <div className="form-group my-1">
            <input name="email" className="form-control" type="email" placeholder="Email Address "
              {...register("email",{required:true})}
            />
            {errors.email && <span className="text-danger">Email is required.</span>}
          </div>
          <div className="form-group my-1">
            <textarea name="purpose" rows="3" className="form-control" placeholder="Your Message Here..."
              {...register("purpose",{required:true})}
            ></textarea>
            {errors.purpose && <span className="text-danger">Message is required.</span>}
          </div>
          {!isLoading ?<button  className="btn ml-auto mt-2" id="btnSend"><i className="fa fa-paper-plane"></i>Send</button> :
          <BeatLoader color='#ccc' className='btn-loader text-center my-3' speedMultiplier={0.9} />}
        </form>
        <ToastContainer />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div `
  #contact_mail {
    background: linear-gradient(90deg, rgba(4,218,253,1) 7%, rgba(51,152,220,1) 36%, rgba(106,13,199,0.7544059860272234) 78%);
  }
  #contact_mail form{
    width:85%;
    margin:5% auto;
    padding:20px;
  }
  @media screen and (max-width:480px){
    #contact_mail{
      width:100%;
    }
    #contact_mail form{
      width:98%;
      padding: 10px;
    }
  }
  #contact_mail form input, #contact_mail textarea{
    background-color:#e8e7e76a;
    border:none;
  }
  #contact_mail form input:focus, #contact_mail textarea:focus{
    outline:hidden;
    background:#fff;
  }

  #btnSend{
    padding:5px 25px;
    text-transform:uppercase;
    font-size:1.3em;
    font-weight:800;
    background:#fff;
    transition:ease 1s;
    color:#8F49D5;

    i{
      margin-right:15px;
    }
  }
  #btnSend:hover{
    background:inherit;
    border:0.5px solid #fff;
  }
  #contact_mail form p, #contact_mail form h5 {
    color: #fff;
  }
  .c_icon {
    color: #8E48D4 !important;
  }
  .btn-loader{
    width:140px;
    padding:5px 15px;
    border-radius:5px;
    background:#8f49d5;
  }
`;