import React from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import {BeatLoader} from "react-spinners"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Axios} from '../../utils/middleware/config';
import { useEffect } from 'react';



export const CourseSignupForm = () => {

  const [courses, setCourses] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const {register, handleSubmit,reset, formState:{errors}} = useForm();

  const handleReg = async(data) =>{
    setIsLoading(true);
    // console.log(data)
    const payload ={
      fullName: data.fullName,
      email: data.email,
      mobile: data.phoneNumber,
      courseId: data.course
    }
    try {
      const result = await Axios.post('/student/course', payload);
      console.log('mainResult', result);
      if(result.data.data){
        toast.success('Registration was Successful.', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          reset();
          setIsLoading(false);
          document.getElementById('courseCloseModal').click();
        }, 3000);
      }
    } catch (error) {
      let {message} = error.response.data;
      const validationError =  error.response.data.data ? error.response.data.data[0] : null;

      setTimeout(() => {
        if(validationError){
          toast.error( validationError.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            theme: "colored",
          });
          setIsLoading(false);
          return;
        }
        toast.error( message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: "colored",
        });
        reset();
        setIsLoading(false);
      }, 3000);
    }
  }

  const fetchCourses = async() => {
    try {
      const {data} = await Axios.get('/courses');
      if(data){
        const {course} = data.data;
        setCourses(course);
      }
    } catch (error) {
      
    }
  }

  useEffect(() => {
    if(!courses.length){
      fetchCourses()
    }
  },[courses]);

  return (
    <Wrapper>
      <div className="container-fluid" id="studentReg">
        <div className="row">
          <div className="col-md-5 col-sm-12">
            <img className="img-fluid regImg" src={require("../../utils/Img/career/talent_hunt.png")} alt=''/>
          </div>
          <div className="col-md-7 c0l-sm-12">
            <div className="m-3">
              <form onSubmit={handleSubmit(handleReg)} >
                <div className="form-group my-2">
                  <p className="text-danger">* All fields are required</p>
                </div>
                <div className="form-group my-2">
                  <input name="fullName" className="form-control" placeholder="Fullname"
                    {...register("fullName", {required:true})}
                  />
                  {errors.fullName && <span className="text-danger">Fullname is required.</span>}
                </div>
                <div className="form-group my-2">
                  <input name="email" className="form-control" placeholder="Email Address" 
                    {...register("email", {required:true})}
                  />
                  {errors.email && <span className="text-danger">Email is required.</span>}
                </div>
                <div className="form-group my-2">
                  <input name="phoneNumber" className="form-control" placeholder="Phone Number"
                    {...register("phoneNumber", {required:true})}
                  />
                  {errors.phoneNumber && <span className="text-danger">Phone Number is required.</span>}
                </div>
                <div className="form-group my-2">
                  <select name='course' className="form-control"
                    {...register('course',{required:true})}
                  >
                    <option value ="">Select Prefered Course</option>
                    {courses.map((c) =>{
                     return <option key={c.id} value={c.id}>{c.title}</option>
                    })}
                    {/* <option value="Product Management">Product Management</option>
                    <option value="Front-end Web Development">Frontend Web Development</option>
                    <option value="Back-end Web Development">Backend Web Development</option>
                    <option value="Game Development Bootcamp">Game Development</option>
                    <option value="UI/UX Design">UI/UX Design</option>
                    <option value="Web3">Web3</option> */}
                  </select>
                  {errors.course && <span className='text-danger'>Course is required.</span>}
                </div>
                <div className="form-group my-2">
                  {!isLoading ? <button className="btn font-weight-bolder btn-block">Register Now</button> :
                    <BeatLoader className='my-4 text-center' speedMultiplier={0.9} />
                  }
                </div>
              </form>
              <ToastContainer />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div `
  #studentReg{
    .regImg{
      height:100%;
      width:auto;
    }
    form {
      padding: 15px;
      border-radius: 5px;
      margin: 15px auto 0 auto;
      width: 98%;
      box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;

      button{
        width:100%;
        font-weight:800;
        background:#13364c;
        color:#fff;
        transition:1s ease-out;
      }

      button:hover{
        background:#133;
      }
    }
  }
`;