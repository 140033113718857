import React from 'react';
import styled from 'styled-components';


export const AdminNav = () => {

  return (
    <Wrapper>
      <div className="social-icons">
        <div>
          <i className="fa fa-envelope mr-2 text-white icons" aria-hidden="true"></i>
          <a href="mailto:xpressdreams.ng@gmail.com" className="text-white-50">xpressdreams.ng@gmail.com</a>
        </div>
        <div >
          <i className="fab fa-whatsapp mx-2 icons whatsapp" aria-hidden="true"></i>
          <i className="fab fa-twitter-square mx-2 icons" aria-hidden="true"></i>
          <i className="fab fa-facebook-square mx-2 icons" aria-hidden="true"></i>
          <i className="fab fa-instagram mx-2 icons" aria-hidden="true"></i>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div `
  .social-icons{
    background:#000;
    color:#ccc;
    display:flex;
    padding:10px 15px;
    align-items:center;
    justify-content:space-between;

    div a{
      text-decoration:none;
      margin-left: 5px;
    }

    @media screen and (max-width:380px){
      padding:5px;
      div a{
        font-size:12px;
      }
    }
  }
 
`;