import React from 'react'
import styled from 'styled-components';

export const MockBlog = () => {
  return (
    <Wrapper className='blog'>
      <div className='m-0 p-0' >
        <div className="postImg">
          <img src={require("../../utils/Img/ai.jpg")} alt="" className="img-fluid" /> 
        </div>
        <div className="category d-flex gap-2">
          <span>Technology</span>
          <span>Software Development</span>
          <span>AI</span>
        </div>
        <div className="info d-flex gap-3 ms-4">
          <span className="date">{new Date().toDateString()}</span>
          <span><i className="fas fa-comment fa-sm fa-fw"></i>  24 Comments</span>
          <span className='like'><i className="fas fa-heart  fa-sm fa-fw"></i> 37</span>
        </div>
        <div className="px-4 py-2 mb-3">
          <h1 className="title">Artificial Intelligence</h1>
          <p>
            Artificial Intelligence (AI) refers to the field of computer science dedicated to creating 
            systems capable of performing tasks that normally require human intelligence. 
            These tasks include problem-solving, learning, reasoning, and understanding language. 
            AI encompasses various approaches, including machine learning, where algorithms improve 
            through experience, and neural networks, which mimic the brain's structure to process 
            information.
          </p>
          <p>
            AI is applied in numerous domains, such as natural language processing (e.g., chatbots), 
            computer vision (e.g., facial recognition), robotics, and data analysis. While AI offers significant 
            advancements and efficiencies, it also raises ethical and societal concerns, such as job displacement 
            and privacy issues. Ongoing research aims to address these challenges while enhancing AI's capabilities and applications.
          </p>
        </div>
      </div>
    </Wrapper>
    
  )
}

const Wrapper = styled.div `
   .blog{
      box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
      min-height: 475px;
      margin-bottom:10px;
      .postImg{
        width:100%;
        img{
          height:95%;
          width:100%;
        }
      }
      .category{
        position:relative;
        top:-12px;
        left:10px;
        span{
          background:#67dafb;
          color:#fff;
          padding:5px 10px;
          font-weight:700;
        }
      }
      .info{
        .like{
          cursor:pointer;
        }
        .fa-heart{
          color:red !important;
        }
      }
      .title{
        font-weight:800;
        font-size:20px;
        line-height:1.2;
        margin-top:5px;

        @media screen and (max-width:480px){
          font-size:18px;
        }
      }
      // show blog post few contents
      .post-content{
        display: -webkit-box;
        overflow : hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical; 

        @media screen and (max-width:480px){
          -webkit-line-clamp: 4;
        }
      }
     
    }
  }
`;
