import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams, useNavigate } from 'react-router-dom';
import {Axios} from '../../utils/middleware/config';
import { CloudinaryImageUrl } from '../../utils/helper';
import { BeatLoader } from 'react-spinners';


export const Cart = () => {
  let params = useParams();
  const navigate = useNavigate();
  const id = params.id;

  const [project, setProject] = useState(null);
  const [qty, setQty] = useState(0);
  const [isLoading, setIsLoading] = useState(false)

  const loadProject = async (projId) => {
    try {
      const {data} = await Axios.get(`/projects/${projId}`);
      if(data.data.length){
        console.log(data.data);
        setProject(data.data[0])
        setQty(1);
      }
    } catch (error) {
      
    }
  }
  const handleCancel = () =>{
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      navigate(-1)
    }, 3000);
  }

  useEffect(() => {
    loadProject(id);
  },[id])
  return (
    <Wrapper className='container my-3'>
      {project !== null? <>
        <table className="table">
          <thead className='thead-dark'>
            <tr>
              <th></th>
              <th>PROJECT</th>
              <th>PRICE</th>
              <th>QUANTITY</th>
              <th>SUBTOTAL</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><img src={CloudinaryImageUrl(project.image_url)} alt="" className="img-fluid proj-img" /></td>
              <td>{project.title}</td>
              <td>{project.price.toLocaleString()}</td>
              <td>{qty}</td>
              <td>₦{(parseInt(qty) * parseInt(project.price)).toLocaleString()}</td>
              <td><i className="fa fa-trash mt-2 deleteTag" aria-hidden="true"></i></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td className='border-none'></td>
              <td className='fw-bold'>Due Payment</td>
              <td className='fw-bold'>₦{(parseInt(qty) * parseInt(project.price)).toLocaleString()}</td>
            </tr>
          </tbody>
        </table>
        <div className="btn_div">
          {!isLoading ? <><button className='btn btn-danger me-3' onClick={()=>handleCancel()}>Cancel</button>
          <button className='btn btn-primary'>Proceed to Checkout</button></> :
          <BeatLoader color='#ccc' className='loader' speedMultiplier={0.9}/>}
        </div>
      </>:
      <p className="lead">No Item in the cart</p>
      }
    </Wrapper>
  )
}

const Wrapper = styled.div `
  .deleteTag{
    margin-top:25px;
    color:red;
    padding:5px;
    font-size:1.3em;
    font-weight:700;
    cursor:pointer;
  }
  .proj-img{
    height:70px;
    width:auto;
  }
  .btn_div{
    width:max-content;
    margin-left:auto;
    margin-top:30px;
    .btn{
      padding:10px 25px;
      font-weight:800;
    }
    .loader{
      min-width:200px !important;
    }
  }
`;