import React from 'react';
import styled from 'styled-components';


export const About = () => {
  return (
    <Wrapper>
      <div className="container-fluid pt-4">
        <div className="row">
          <div className="col-md-5 offset-md-1">
            <h1>About Us</h1>
            <p className="lead">
              We are dedicated to driving your business forward with cutting-edge software solutions. 
              As your partner, we bring expertise and creativity to develop innovative technologies 
              that meet your unique needs and foster growth.
            </p>
            <div className='biz_add'>
              <img className="img-fluid" src={require("../utils/Img/about/business-investor-gaining-profit-from-investment.gif")} alt=''/>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-6 content_div">
                <div id="mission">
                  <h4>Our Mission</h4>
                  <img className="img-fluid" src={require("../utils/Img/about/image-box1.jpg")} alt=''/>
                  <p className="desc">
                    Our mission is to deliver unique and premium solutions that meet our clients’ business objectives, 
                    ensuring customer satisfaction through the use of efficient and cost-effective technologies.
                  </p>
                </div>
                <div id="philosopy">
                  <h4>Our Philosophy</h4>
                  <img className="img-fluid" src={require("../utils/Img/about/image-box3.jpg")} alt=''/>
                  <p className="desc">
                    Our philosophy is to help clients reimagine their businesses and projects by creating impactful 
                    digital engineering solutions, leveraging the latest technologies.
                  </p>
                </div>
              </div>
              <div className="col-6 content_div">
                <div id="vission">
                  <h4>Our Vission</h4>
                  <img className="img-fluid" src={require("../utils/Img/about/vision.webp")} alt=''/>
                  <p className="desc">
                    Our vission is to dedicate our best efforts of time, to develop technology solutions that enable our clients to overcome business challenges, 
                    improve productivity. Assist individuals and firms fast track their businesses ideations to live.
                  </p>
                </div>
                <div id="strategy">
                  <h4>Our Strategy</h4>
                  <img className="img-fluid" src={require("../utils/Img/about/image-box4.jpg" )}alt=''/>
                  <p className="desc">
                  Our strategy is to deliver technology solutions that drive growth and mitigate risk while prioritizing an exceptional user experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="activity">
        <div className="row">
          <div className="col-md-7 col-sm-12">
            <img className="img-fluid" src={require("../utils/Img/about/image1-about.png")} alt=''/>
          </div>
          <div className="col-md-5 col-sm-12 side-div" >
            {/* <h1>Our Technology Stack</h1> */}
            <div className="tech_stack text-center my-3">
              <div className="tech_icon">
                <i className="fa-brands fa-angular"></i>
              </div>
              <div className="tech_icon">
                <i className="fa-brands fa-react"></i>
              </div>
              <div className="tech_icon">
                <i className="fa-brands fa-unity"></i>
              </div>
              <div className="tech_icon">
                <i className="fa-brands fa-node-js"></i>
              </div>
            </div>
            <div className="tech_note mt-5">
              <h4>Software Development</h4>
              <p>We help you innovate, make better decisions, and stay ahead of the competition.
                Our experienced team harnesses the latest technologies and 
                agile methodologies to create cutting-edge software applications that drive digital transformation.
              </p>
              <p className="more">
                <i className="fa-solid fa-circle-arrow-right"></i> <span>Learn More</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="team container my-5">
        <div className="">
          <h1>Our Leadership Team</h1>
          <p>
            We help businesses elevate their value through custom software development,<br/>
            product design, QA and consultancy services.
          </p>
        </div>
        <div className="team_photo">
          <div className="row">
            <div className="col-md-4 col-sm-12">
                <div className="member_wrap">
                  <div className="pix">
                    <img className="img-fluid" src={require("../utils/Img/about/team/member3.jpg")} alt='' />
                  </div>
                  <div className="social_link">
                    <div>
                      <i className="fa-brands fa-twitter"></i>
                    </div>
                    <div>
                      <i className="fa-brands fa-linkedin"></i>
                    </div>
                    <div>
                      <i className="fa-brands fa-facebook"></i>
                    </div>
                    <div>
                      <i className="fa-brands fa-instagram"></i>
                    </div>
                  </div>
                  <div className="text-center  py-2 ">
                    <h4 className="mb-0">Obinna Vincent</h4>
                    <p className="lead">CEO of the Company</p>
                  </div>
                </div>
            </div>
            <div className="col-md-4 col-sm-12">
                <div className="member_wrap">
                  <div className="pix">
                    <img className="img-fluid img-rounded" src={require("../utils/Img/about/team/member3.jpg")} alt=''/>
                  </div>
                  <div className="social_link">
                    <div>
                      <i className="fa-brands fa-twitter"></i>
                    </div>
                    <div>
                      <i className="fa-brands fa-linkedin"></i>
                    </div>
                    <div>
                      <i className="fa-brands fa-facebook"></i>
                    </div>
                    <div>
                      <i className="fa-brands fa-instagram"></i>
                    </div>
                  </div>
                  <div className="text-center py-2">
                    <h4 className="mb-0">Ogbonna Francis</h4>
                    <p className="lead">CTO and CO-Founder of the Company</p>
                  </div>
                </div>
            </div>
            <div className="col-md-4 col-sm-12">
                <div className="member_wrap">
                  <div className="pix">
                    <img className="img-fluid img-rounded" src={''} alt='' />
                  </div>
                  <div className="social_link">
                    <div>
                      <i className="fa-brands fa-twitter"></i>
                    </div>
                    <div>
                      <i className="fa-brands fa-linkedin"></i>
                    </div>
                    <div>
                      <i className="fa-brands fa-facebook"></i>
                    </div>
                    <div>
                      <i className="fa-brands fa-instagram"></i>
                    </div>
                  </div>
                  <div className="text-center py-2">
                    <h4 className="mb-0">Jennifer Ogbuabor</h4>
                    <p className="lead">CO-Founder of the Company</p>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="achive mb-5">
        <div className="row text-center">
          <div className="col-md-3">
            <h3>450+</h3>
            <p>
              <i className="fa-solid fa-certificate"></i> Active Clients
            </p>
          </div>
          <div className="col-md-3">
            <h3>720+</h3>
            <p>
              <i className="fa-solid fa-certificate"></i> Projects Completed
            </p>
          </div>
          <div className="col-md-3">
            <h3>15+</h3>
            <p>
              <i className="fa-solid fa-certificate"></i> Team Advisors
            </p>
          </div>
          <div className="col-md-3">
            <h3>4+</h3>
            <p>
              <i className="fa-solid fa-certificate"></i> Glorious years
            </p>
          </div>
        </div>
      </div>

      <div className="tech_trend container my-5">
        <div className="row">
          <div className="col-md-5 col-sm-12">
            <blockquote className="blockquote  font-italics mt-3">
              <i className="fas fa-quote-left fa-lg text-warning me-2"></i>
              Software quality means delivering products that are reliable, efficient, and user-friendly. 
              It involves rigorous testing and adherence to best practices to ensure that software meets 
              user needs and performs consistently well.
            </blockquote>
          </div>
          <div className="col-md-7 col-sm-12">
            <div className="x-pix">
              <img className="img-fluid" src={require("../utils/Img/about/team/xpress-pix.png")} alt=''/>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div `
  
  #vission, #philosopy, #mission, #strategy{
    position: relative;
    max-height: 320px;
    margin-bottom: 25px;
    overflow-y:hidden;
  }
  #vission h4, #philosopy h4, #mission h4, #strategy h4 {
    text-transform: uppercase;
    color: #fff;
    position: absolute;
    top: 40%;
    right: 4%;
  }
  .desc{
    display:none;
    padding:15px;
    position: absolute;
    top: 3%;
    color:#fcfcfc;
    font-size:16px;
  }
  @media screen and (max-width:768px){
    .content_div {
      padding:1px;
    }
    .desc{
      padding:5px;
      font-size: 12px;
    }
    #vission:hover img, #philosopy:hover img, #mission:hover img, #strategy:hover img{
      height:100%;
    }
  }
  @media screen and (max-width:320px) {
    .desc {
      font-size: 10px;
    }
  }
  #vission:hover img, #philosopy:hover img, #mission:hover img, #strategy:hover img{
    opacity: 0.7;
  }
  #vission:hover .desc, #philosopy:hover .desc, #mission:hover .desc, #strategy:hover .desc {
    display: block;
  }
  #activity {
    background-color: #211F3B;
    background-image: url(${require("../utils/Img/about/bg-art-5.png")}),url(${require("../utils/Img/about/bg-art-6.png")});
    background-repeat: no-repeat, no-repeat;
    background-position: left top, right bottom;
    color: #fff;
    padding: 8% 2% 0% 0;
  }
  .tech_stack{
    display:flex;
    flex-direction: ;
    gap:10px;
    
  }
  .tech_icon {
    border: 0.8px solid #fff;
    border-radius: 5px;
    padding: 20px;
    margin: 0 10px;
    font-size: 36px;
    width: 18%;
    height: auto;
    text-align: center;
    color: #67dafb;
    transition: all ease 1.1s;
  }
  @media screen and (max-width:768px){
    #activity h1{
      font-size:30px;
      font-weight:800;
    }
    .tech_stack{
      flex-direction:row;
      gap:1px;
    }
    .tech_icon {
    width:100%;
    margin:3px;
    height: auto;
    font-size:24px;
    }
  }
  .tech_icon:hover{
    background:#67dafb;
    color:#fff;
    border:0.8px solid #67dafb;
  }
  .tech_note .more{
    color:#67dafb;
    font-size:1.2em;
    text-transform:uppercase;
    font-weight:500;
    cursor:pointer;
  }
  .tech_note .more span{
    margin-left:15px;
    transition: all ease 1s;
  }
  .tech_note .more:hover span{
    margin-left:4px;
    color:#7bd3fdc4;
  }
  @media screen and (max-width:600px){

    #activity h1 {
      font-size:36px;
      padding:10px;
    }
    .tech_note{
      padding: 10px;
    }
  }
  .member_wrap {
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  }
  .member_wrap .pix {
    height: 285px;
    overflow: hidden;
    border-top-left-radius: 5px;
    border-top-right-radius:5px;
  }
  .member_wrap .social_link {
    width: 40px;
    height:120px;
    background: #211F3B;
    color:#fff;
    text-align:center;
    position:absolute;
    right:2px;
    bottom:40%;
  }
  .member_wrap .social_link div{
    margin: 5px;
    transition:ease 1s;
  }
  .member_wrap .social_link div:hover{
    transform:scale(1.3);
    cursor:pointer;
  }
  .achive {
  background: linear-gradient(90deg, rgba(4,218,253,1) 7%, rgba(51,152,220,1) 36%, rgba(106,13,199,0.9756945014333859) 78%);
  color:#fff;
  padding:25px;
  }
  .achive h3{
    margin-bottom:0;
    font-weight:800;
    font-size:32px;
  }
  .achive p{
    text-transform:uppercase;
  }
  .x-pix{
    margin-top:2%;
    height:80%;
    width:auto;
  }
  .x-pix img{
    height:100%;
    width:auto;
  }
  @media screen and (width:768px){
    .x-pix {
      margin-top:10%;
      padding:15px;
      height:60%;
    }
  }
`;