import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {Axios,AxiosForm} from '../../utils/middleware/config';
import { AdminLayout } from './AdminLayout';
import { useForm } from 'react-hook-form';
import { BeatLoader } from 'react-spinners';


export const AddProject = () => {

  const [imgUrl, setImgUrl] = useState(null);
  const [previewImages, setPreviewImages] = useState([]);
  const [progressLevel, setProgressLevel] = useState(0);
  const [projectCategories, setProjectCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [projectId, setProjectId] = useState('');
  const [techstack, setTechstack] = useState('');
  const [devTools, setDevTools] = useState([]);
  const {register, handleSubmit, formState:{errors}} = useForm();

  
  const loadProjectCategory = async(page) =>{
    const {data} =await Axios.get(`/project/category`);
    if(data){
      console.log('found')
      console.log(data.data);
      const {category} = data.data
      setProjectCategories(category)
    }
  }

  const addTechStack = (tech) =>{
    if(tech ==="" || tech === undefined){
      return;
    }
    const div = document.getElementById('tech-stack');
    const stack = document.createElement('span');
    stack.innerText = tech;
    div.appendChild(stack);
    setDevTools([...devTools,tech]);
    setTechstack('');
    console.log(devTools)
  }
  const uploadImage = () => document.getElementById('upload').click();
  const uploadImagePreview = () => document.getElementById('uploadPreview').click();
  const handleUpload = async (e) =>{
    setImgUrl(e.target.files[0]);
  }
  const handleImagePreview = async (e) =>{
    let urls = [];
    for (let index = 0; index < e.target.files.length; index++) {
      urls.push(e.target.files[index]);
    }
    setPreviewImages(urls);
  }

  const uploadProject = async (formInput) => {
    setIsLoading(true)
    if(imgUrl === null){
      return;
    }
    const formData = new FormData();
    console.log(devTools)
    formData.append("title",formInput.title);
    formData.append("description",formInput.description);
    formData.append("category_id",formInput.category);
    formData.append("price", formInput.price);
    formData.append("image",imgUrl);
    formData.append("tech_stack",JSON.stringify(devTools));
    formData.append("demo_link",formInput.demo_link);

    try {
      const {data} = await AxiosForm.post(`/project`,formData);
      if(data.success){
        setImgUrl(null);
        console.log(data.data);
        const {id} = data.data;
        console.log(id)
        setProjectId(id);
        setTimeout(() => {
          setIsLoading(false);
          setProgressLevel(75);
        }, 3000);
      }else{setIsLoading(false)}
      
    } catch (error) {
      console.log(error.response);
      setIsLoading(false);
    }
  }

  const uploadImagePreviewToDb = async (proj) => {
    setIsLoading(true);
    console.log(proj)
    const previewData = new FormData();
    previewData.append('id',proj)
    previewImages.forEach(img => {
      previewData.append('images',img);
    });
    
    try {
      const {data} = await AxiosForm.put(`/project/${proj}/preview`,previewData);
      if(data.success){
        console.log(data.data);
        setTimeout(() => {
          setProgressLevel(100);
          setPreviewImages([])
          setIsLoading(false);
        }, 1500);
        setTimeout(() => {
          setProgressLevel(0)
        }, 3000);
      }else{setIsLoading(false)}
      
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  useEffect(() =>{
    if(projectCategories.length < 1){
      loadProjectCategory();
    }
  },[projectCategories])

  return (
    <Wrapper>
      <AdminLayout>
        <div className="container-fluid">
          <div className="stepper">
            <span className={progressLevel < 75 ?'step':'step completed'}>1</span>
            <hr className='separator w-75'/>
            <span className={progressLevel !== 100 ?'step':'step completed'}>2</span>
          </div>
          <form onSubmit={handleSubmit(uploadProject)}>
            <div className="row">
              <div className={progressLevel < 75? "col-md-6 col-sm-12" :"col-md-6 col-sm-12 hide"}>
                <div className="form-group mt-1">
                  <input type="text" name="title" placeholder='Project Title' className="form-control" 
                    {...register('title',{required:true})}
                  />
                  {errors.title && <span className='text-danger'>Project title is required</span>}
                </div>
                <div className="form-group mt-1">
                  <select name="category" id="" className="form-control"
                    {...register('category',{required:true})}
                  >
                    <option value="">Select Project Category</option>
                    {
                      projectCategories.map((category) =>{
                        return <option key={category.id} value={category.id}>{category.name}</option>
                      })
                    }
                  </select>
                  {errors.category && <span className='text-danger'>Category is requird.</span>}
                </div>
                <div className="form-group mt-1">
                  <textarea name="description" placeholder='Project Description' cols="3" rows="4" className="form-control"
                    {...register('description',{required:true})}
                  ></textarea>
                  {errors.description && <span className='text-dnager'>Description is required.</span>}
                </div>
                <div id="tech-stack"></div>
                <div className='input-group mt-1'>
                  <input type="text" className="form-control" value={techstack} placeholder="Enter tech stack" aria-label="Enter tech stack" aria-describedby="add_stack_Btn" 
                    onChange={(e) =>setTechstack(e.target.value)}
                  />
                  <div className="input-group-append">
                    <button className="input-group-text" type='button' id="add_stack_Btn" onClick={() => addTechStack(techstack)}>ADD</button>
                  </div>
                </div>
                <div className="form-group mt-1">
                  <input type="text" name="demo_link" placeholder='Video Demo Url' className="form-control" 
                    {...register("demo_link",{required:false})}
                  />
                </div>
                <div className="form-group mt-1">
                  <div className="row">
                    <div className="col-9">
                      <input type="text" name="price" placeholder='Project Price' className="form-control" 
                        {...register('price',{required:true})}
                      />
                      {errors.price && <span className='text-danger'>Price is required.</span>}
                    </div>
                    <div className="col-3">
                      <i onClick={uploadImage} className="fa-solid fa-image addImg" ></i>
                    </div>
                  </div>
                </div>
                <div className="form-group mt-2 d-none">
                  <input type="file" name="image" id="upload"accept="image/*" onChange={(e)=>handleUpload(e)} className="form-control" />
                </div>
                <div>
                  {!isLoading && imgUrl !==null && <button className="btn-primary-block">Upload Project</button>}
                  {isLoading && imgUrl !==null && <BeatLoader color='#ccc' className='loader' speedMultiplier={0.9} />}
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <img src={imgUrl !== null? URL.createObjectURL(imgUrl) : ""} alt="" className="img-fluid proj-img" />
                {previewImages.length > 0 && <div className="row">
                    <hr className='mt-2'/>
                    {
                      previewImages.map((image,index) =>{
                        return <div className="col-md-4 mt-1 col-sm-6" key={index}>
                          <img src={URL.createObjectURL(image)} alt=""  className="img-fluid preview" />
                        </div>
                      })
                    }
                    <hr/>
                  </div>
                }
                
                {progressLevel >= 75 && <div className='d-flex justify-content-between'>
                  <div className='flex-grow-1 me-2'>
                    {!isLoading && progressLevel >= 75 &&<button className="btn-primary-block"
                      onClick={() => uploadImagePreviewToDb(projectId)}
                    >upload</button>}
                    {isLoading && progressLevel >= 75 &&<BeatLoader color='#ccc' className='loader' speedMultiplier={0.9}/>}
                  </div>
                  <p className='text-end'>
                    <span className="form-group mt-2 d-none">
                      <input type="file" name="image" id="uploadPreview"accept="image/*" multiple onChange={(e)=>handleImagePreview(e)} className="form-control" />
                    </span>
                    <span className="mb-0">Add Preview images</span><br />
                    <i onClick={uploadImagePreview} className="fa-solid fa-image addImg"></i>
                  </p>
                </div>}
              </div>
            </div>
          </form>
        </div>
      </AdminLayout>
    </Wrapper>
  )
}

const Wrapper = styled.div `
  .stepper{
    display:flex;
    width: 60%;
    margin: 5px auto;
    align-items:center;
    justify-contents:space-between;
//dbf40103-1ea4-46b3-b9a3-1e6d5b95ace0
    .step{
      border:1px solid #949392e0;
      background:#949392e0;
      color:#fff;
      height:25px;
      width:25px;
      border-radius:50%;
      text-align:center;
    }
    .separator{
      margin:0 4px;
    }
    .completed{
      border:1px solid #026c05;
      background:#026c05;
    }
  }
  form{
    margin:3% auto;
    width:85%;

    @media screen and (max-width:480px){
      width:98%;
    }
  }
  #tech-stack{
    display:flex;
    padding:1px 5px;
    gap:3px;
    flex-flow: row wrap;

    span{
      margin-top:3px;
      padding:4px;
      background: #ccc;
      color:#fff;
      border-radius:5px;
    }
  }
  #add_stack_Btn{
    background:#5953f2;
    color:#fff;
    font-weight:bold;
    transition: 1s ease all; 
  }
  #add_stack_Btn:hover{
    background:#000c4b;
  }
  .addImg{
    font-weight:800;
    font-size:2.5em;
    cursor:pointer;
    color:#7049ca;
    margin-left:13px;
  }
  .proj-img{
    height:50%;
    width: auto;
  }
  .preview{
    height:80%;
    width:auto;
  }
  .flex-div{
    display:flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    

    @media screen and (max-width:320px){
      p{
        font-size:12px;
      }
    }
    @media screen and (width:768px){
      p{
        font-size:13px;
      }
    }
  }
 .hide{
  visibility: hidden;
 }
`;