import React from 'react';
import styled from 'styled-components';
import pricing from "../utils/Img/pricing.svg";
import transparent from "../utils/Img/transparent.svg";
import talent from "../utils/Img/talent.svg";
import agile from "../utils/Img/agile.svg";
import { HireRequest } from '../components/researchComponents/HireRequest';

export default function Home() {
  return (
    <HomeWrapper className='container-fluid'>
      
      <div id="carouselExampleCaptions" className="carousel slide carousel-fade" data-bs-ride="carousel">
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={require("../utils/Img/slide1-home1.jpg")} className="d-block w-100" alt="..."/>
            <div className="carousel-caption d-none d-md-block">
              <h2>A truly Tailored Approach</h2>
              <p>
                Our passion is to transform your Ideas into compelling software applications.
              </p>
            </div>
          </div>
          <div className="carousel-item">
            <img src={require("../utils/Img/slide2-home1.jpg")} className="d-block w-100" alt="..."/>
            <div className="carousel-caption d-none d-md-block">
              <h2>Dedicated Development Team</h2>
              <p>
                Hire top tech talent with necessary niche skills, deep expertise and quickly scale your delivery capacity.
              </p>
            </div>
          </div>
          <div className="carousel-item">
            <img src={require("../utils/Img/slide3-home1.jpg")} className="d-block w-100" alt="..."/>
            <div className="carousel-caption d-none d-md-block">
              <h2>Let's Talk</h2>
              <p>
                At Xpress Dreams we deliver software solutions at scale.<br/> We're  here to speed up your digital transformation jorney!
              </p>
            </div>
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      <div className="container mt-4">
        <div className="row">
          <div className="col-md-5 col-sm-12 partner">
            <h1>Your Partner for <br/> Software <span>Innovation</span></h1>
            <p>
              We help you uncover the latest thought provoking insight into learning and skill recognition to boost your business.<br/>
              At Xpress Dreams, We're committed to creating products and services that are carefully tailored for usability and high performance with customer satisfaction in mind.
            </p>
          </div>
          <div className="col-md-7 col-sm-12">
            <img src={require("../utils/Img/image1-home1.png")} alt="" className="img-fluid" />
          </div>
        </div>
      </div>

      <div className="container-fluid card-wrapper py-3">
        <div className="text-center">
          <h1 className="h-styled">Why Choose US</h1>
          <p>
            We develop, design and manage software projects<br/>
            while providing a monitoring framework for our clients.
          </p>
        </div>
        <div className="row my-4">
          <div className="col-md-3 col-sm-12 col-xs-12">
            <div className="card mb-3 c_card">
              <div className="p-3">
                <img className="card-img" src={talent} alt=''/>
                <h3 className="card-title">Talent Pool</h3>
                <p className="card-text">
                  We've talented software engineers committed to delivering high
                  quality products within specified time frame.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-3 col-sm-12 col-xs-12">
            <div className="card shadow-sm mb-3 c_card">
              <div className="p-3">
                <img className="card-img" src={agile} alt=""/>
                <h3 className="card-title">Agile Methodology</h3>
                <p className="card-text">
                  Our agile approach to product development ensures prompt delivery of value at every point in time.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-3 col-sm-12 col-xs-12">
            <div className="card shadow-sm mb-3 c_card">
              <div className="p-3">
                <img className="card-img" src={transparent} alt="" />
                <h3 className="card-title">Transparency</h3>
                <p className="card-text">
                  Our transparent methods ensure that you are always up to date with the progress of your project.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-3 col-sm-12 col-xs-12">
            <div className="card shadow-sm mb-3 c_card">
              <div className="p-3">
                <img className="card-img" src={pricing} alt="" />
                <h3 className="card-title">Friendly Pricing</h3>
                <p className="card-text">
                  Our pricing is tailored for convenience. We charge per sprint so you only pay for what has been done.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="testimony">
          <div className="testimony-text p-3  mx-auto">
            <p>Customer satisfaction is our utmost priority.
              We orchestrate a creative mindset and a structured approach for maximum benefit of our clients. Offering Services with the best price.
            </p>
          </div>
          <div className="customer-bg">
            <h1 className="h-styled">25K +</h1>
            <h3 className="mt-0">Happy Customers</h3>
            <p className="mt-4 mb-5">To succeed, every software solution must be deeply <br/>integrated into the existing tech environment.</p>
          </div>
        </div>
      </div>
      

      <div id="service" className="mt-2 mb-5 p-0">
        <h1 className="mb-4 h-styled">Our Services</h1>
        <div className="row">
          <div className="col-md-4 col-xs-12 ">
            <div className="shadow p-3 card ad-card">
              <div className="d-flex align-items-center">
                <img src={require("../utils/Img/web.png")} alt="" className="img-fluid service-icon"/>
                <h4>Web Development</h4>
              </div>
              <div>
                <p>We carry more than just good coding skills. Our experience makes us stand out from other web development.</p>
                <button className="btn btn-sm btn-learn">Learn more</button>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-xs-12">
            <div className="shadow p-3 card ad-card">
              <div className="d-flex align-items-center">
                <img src={require("../utils/Img/mobile.png")} alt='' className="img-fluid service-icons"/>
                <h4>Mobile Development</h4>
              </div>
              <div>
                <p>
                  While we execute a user-centered process, our Mobile Product Strategy encompasses much more. 
                  We create a product that delivers unique value, on-time and on-budget.
                </p>
                <button className="btn btn-sm btn-learn">Learn more</button>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-xs-12">
            <div className="shadow p-3 card ad-card">
              <div className="d-flex align-items-center">
                <img src={require("../utils/Img/enterprise.png")} alt="" className="img-fluid service-icons" />
                <h4>Enterprise Solutions</h4>
              </div>
              <div>                    
                <p>
                  We design enterprise applications to operate in corporate
                  environment such as business or government to enhance the efficiency of services.
                </p>
                <button className="btn btn-sm btn-learn">Learn more</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="lets-build">
        <div>
          <h1 className="mb-0 h-styled">Let's Build Together!</h1>
          <p className="mt-0">Hire a product team from <span className='fst-italic fw-bold brand'>Xpress Dreams.</span></p>
        </div>
        <div className='actionBtn-div'>
          <button className="btn btn-md-lg btn-outline-light" data-bs-toggle="modal" data-bs-target="#hireModal">Hire Now</button>
        </div>
      </div>

      {/* <div id="client-note">
        <div className="row">
          <div className="col-md-6 col-sm-12 ">
            <div className="flip-shadows wrap mt-4">
              <div className="d-flex align-items-center gap-3 mb-3">
                  <img className="img-rounded img-fluid mr-2" src={require("../utils/Img/client_testi.png")} alt='' />
                <div>
                  <h4 className="mb-0">David Amstrong</h4>
                  <p className="mt-0">HR Consultant</p>
                </div>
              </div>
              <div>
                <p>
                  Xpress Dreams provide excellent service and support, speedy recruitment and top-notch talent for teams. Always identifying perfect match
                  skill-set wise.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12 mb-4">
            <div className="flip-shadows wrap mt-4">
              <div className="d-flex align-items-center gap-3  mb-3">
                <img className="img-rounded img-fluid mr-2" alt='' src={require("../utils/Img/client_testi2.png")} />
                <div>
                  <h4 className="mb-0">Sarah Solomon</h4>
                  <p className="mt-0">Director at D-Tech Solutions</p>
                </div>
              </div>
              <div>
                <p>Worked with Xpress Dreams Technology on my last project.
                  The requirement was an "Out of the box" thinking, detailed attention, deep understanding of the industry
                  and high level of UX expertise. 
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
     
      {/* Hire Modal */}
      <div className="modal fade" id="hireModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="hireModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="hireModalLabel">Hire Our Project Team</h1>
              <button type="button" className="btn-close" id='closeModal' data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <HireRequest/>
            </div>
          </div>
        </div>
      </div>
    </HomeWrapper>
  )
}

const HomeWrapper = styled.div `
  h1{
    font-weight:800;
  }
  .partner{
    text-align:left;
    h1{
      font-weight:800;
      margin-top: 4%;

      span{
        color:#7260d5dd;
      }
    }
  }
  .card-wrapper {
    background: #6973BA;
    color: #fff;
  }
  .card-wrapper .card {
    background: rgb(0 0 0 / 0.26);
    min-height:300px;
  }
  .card-wrapper .card h3{
    font-size: 24px;
    font-weight:800;
  }
  .card-wrapper img {
    width: 25%;
    height: auto;
    margin: 8px auto 10px auto;
  }
  .testimony{
    display: grid;
    grid-template-areas: "text bg";
    grid-template-columns:40% 60%;
    gap:10px;
    position:relative;
    
    @media screen and (max-width:480px){
     
      grid-template-areas:"bg"
      "text";
      grid-template-columns:100% 100%; 
      gap:2px;
    }
  }
  .testimony-text{
    grid-area:text;
    font-size:20px;
    font-weight:400;
    margin-top:25px;

    @media screen and (max-width:480px){
      margin-top:0 !important;
      font-size:16px;
      padding:10px !important;
    }
  }
  .customer-bg{
    background-image:url(${require("../utils/Img/bg-counter-2.jpg")});
    background-position:center;
    background-size:cover;
    background-repeat:no-repeat;
    color:#fff;
    padding:15px;
    grid-area:bg;
    position: relative;
    top:-20px;
    right:9px;

    @media screen and (max-width:480px){
      right:0;
    }
  }
  .customer-bg h1{
    font-weight:800;
    font-size:4em;
    margin-bottom:0;
  }

  #service .ad-card{
    height: 250px;
    border:none;

    @media screen and (max-width:480px) {
      height: 230px !important;
      margin-bottom: 25px;
      font-size:14px;
    }
    @media screen and (width:768px){
      font-size:14px;
      height:255px;
    }
  }
  

  #service .btn {
    background: #A29FE6;
    color:#fff;
    position: absolute;
    bottom: 15px;
    font-weight:600;
    font-size:19px;
    line-height:26px;
    transition:ease 1s; 
  }
  #service .btn:hover{
    border:1.2px solid #A29FE6;
    background:#fff;
    color:#A29FE6;
  }

  #lets-build{
    background-image: url(${require("../utils/Img/build_bg.jpg")});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 350px;

    display: flex;
    justify-content: space-between;
    padding: 3% 45px 0  45px;
    color:#fff;
    font-weight:600;
    .brand{
      color:#8575db;
      font-size:1.1em;
    }
    @media screen and (max-width:480px){
      flex-direction:column;
      padding:10px;
      h1{font-weight:700;}
      justify-content: flex-start;
      gap:15px;

      .actionBtn-div{
        text-align:center;
      }
      button{
        width:80%;
        font-weight:800;
        margin:0 auto; 
      }
    }
  } 

  #client-note{
    background-image:url(${require("../utils/Img/bg-testi-home8.png")});
    background-position:center;
    background-repeat:no-repeat;
    background-size:cover;
    margin: 5px auto 10px auto;
    padding:25px;
  }
 
  #client-note .wrap{
    width:97%;
    background:#fff !important;
    padding:10px;

    @media screen and (max-width:480px){
      .d-flex{
        flex-direction:column;
        margin-bottom:0 !important;  
        div{
          text-align:center;
          margin-bottom:0 !important;  
        }
      }
    }
  }
  #client-note .flip-shadows {
    position: relative;
    background: #777;
  }
  #client-note .flip-shadows:before, #client-note .flip-shadows:before {
    z-index: -1;
    position: absolute;
    content: "";
    bottom: 25px;
    left: 10px;
    width: 50%;
    top: 80%;
    max-width: 300px;
    background: #777;
    -webkit-box-shadow: 0 35px 20px #777;
    -moz-box-shadow: 0 35px 20px #777;
    box-shadow: 0 35px 20px #777;
    -webkit-transform: rotate(-8deg);
    -moz-transform: rotate(-8deg);
    -o-transform: rotate(-8deg);
    -ms-transform: rotate(-8deg);
    transform: rotate(-8deg);
  }
  #client-note .flip-shadows:after {
    -webkit-transform: rotate(8deg);
    -moz-transform: rotate(8deg);
    -o-transform: rotate(8deg);
    -ms-transform: rotate(8deg);
    transform: rotate(8deg);
    right: 10px;
    left: auto;
  }
`;