import React from 'react';
import styled from 'styled-components';
import help from "../../utils/Img/admin/helpdesk.svg";
import {DoughnutChart, BarChart,LineChart,PieChart} from "./Charts";
import { AdminLayout } from './AdminLayout';


const completedProjects = {
  items:[12,4,45,10,1,0],
  title: "Completed Projects",
  background: ['#52b7f1','red','green','blue','gray','navy'],
  label:['M.Sc ', 'B.Sc','HND','NCE',"OND",'P.hD']
}

// const coursesRegistered = {
//   items:[12,4,45],
//   title: "Registered Courses",
//   background: ['blue','gray','navy'],
//   label:['Product Design ', 'Backend Dev','Frontend Dev']
// }
const BarData ={
  items:[
    {
      id:1,
      data:[4,6,9],
      bg:'red',
      label:'Masters Project'
    },
    {
      id:1,
      data:[2,1,14],
      bg:'green',
      label:'Masters Project'
    },
    {
      id:1,
      data:[65,8,10],
      bg:'#ccc',
      label:'Degree Project'
    }
  ],
  title:"Projects",
  labels:['January', 'February', 'March']
}
const LineData ={
  items:{
    data:[4500,4900,2500,200],
    color:"#c4c4",
    name:"Total Sales"
  },
  labels:['January',"February","March","April"],
  title:'Revenue'
}
const pieChartData ={
  items:{
    data:[4500,4900,2500,200],
    bg:["#c4c4","blue",'red',"green"],
    name:"Total Sales"
  },
  labels:['January',"February","March","April"],
  title:'Client Orders'
}

export const AdminHome = () => {
  
  return (
    <AdminWrap>
      <AdminLayout>
       <div className="contents">
        <div className='card px-4 py-2 my-3 welcome-banner'>
          <div className="row no-gutters">
            <div className="col-md-7 col-sm-12 mt-3">
              <h4>Welcome Pope Francis</h4>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nostrum provident saepe doloremque! Dolores, 
                aspernatur fugit libero recusandae numquam cum animi explicabo illo provident, sint velit dignissimos porro ducimus harum culpa!
              </p>
            </div>
            <div className='col-md-5 col-sm-12'>
              <img src={help} height={200} alt="help desk" />
            </div>
          </div>
        </div>
        <div className="row text-center">
          <div className="col-md-3 col-sm-6">
            <div className="card p-1">
              <h5>Total Revenue</h5>
              <h6><i className="fa-solid fa-sack-dollar"></i>$5600</h6>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="card p-1">
              <h5>Client Order</h5>
              <i className="fa-solid fa-tag "></i>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="card p-1">
              <h5>Blog Posts</h5>
              <i className="fa fa-comments" aria-hidden="true"></i>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="card p-1">
              <h5>Students</h5>
              <i className="fa fa-graduation-cap" aria-hidden="true"></i>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8 col-sm-12 bar">
            <BarChart data={BarData} />
          </div>
          <div className="col-md-4 col-sm-12">
            <DoughnutChart data ={completedProjects} />
          </div>
          <div className="col-md-8 col-sm-12">
            <LineChart data={LineData} />
          </div>
          <div className="col-md-4 col-sm-12">
            <PieChart data={pieChartData} />
          </div>
        </div>
       </div>
      </AdminLayout>
    </AdminWrap>
  )
}

const AdminWrap = styled.div `
  .contents{
    padding:0 2% 3% 10px;
  }
  .welcome-banner{
    border-left:3px solid green;
  }
  @media screen and (max-width:480px){
    .bar{
      height:300px;
    }
  }
`;
