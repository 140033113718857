import React from 'react';
import { JoinTeam } from '../components/careerComponents/JoinTeam';


export const TeamRegistration = () => {
  return (
    <>
      <JoinTeam />
    </>
  )
}
