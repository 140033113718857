import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import {Axios} from '../../utils/middleware/config';
import { ProjectVideoPreview } from './ProjectVideoPreview';
import { CloudinaryImageUrl } from '../../utils/helper';
import {BeatLoader} from "react-spinners";


export const ProjectDetails = () => {
  let params = useParams();
  const id = params.id;

  const [project, setProject] = useState(null);
  const [relatedProjects, setRelatedProjects] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const loadProject = async(projId) =>{
    // console.log(projId)
    try {
      const {data} = await Axios.get(`/projects/${projId}`);
      if(data.success){
        setProject(data.data[0]);

        const response = await Axios.get(`/projects/${projId}/related`);
        if(response.data.success){
          setRelatedProjects(response.data.data);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  }
  const handleAddToCart = ()=>{
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      window.location.href =`/research/project/${id}/cart`;
    }, 3000);
  }

  useEffect(() =>{
    if(id !== undefined){
      loadProject(id);
    }
  
  },[id])

  return (
    <Wrapper className='container-fluid mt-2 px-3'> 
      <div className="mb-3">
        { project !== null ? <> <div className="row no-gutters">
          <div className="col-md-7">
            <img src={project === null? "":CloudinaryImageUrl(project.image_url)} className="card-img" alt="..."/>
              {project.preview[0] !== undefined  && <div className="row no-gutters mt-2">
                  <h5>Preview Images</h5>
                  {
                    project.preview.map((image,index) => {
                      return <div className="col-3" key={index}>
                      <img src={CloudinaryImageUrl(image)} alt="" className="img-fluid previewImg" />
                    </div>
                    })
                  }
                </div>
            }
          </div>
          <div className="col-md-5">
            <div className="card-body mt-3">
              <h5 className="card-title">{project.title}</h5>
              <p className="card-text">
                {project.description}
              </p>
              <p>Price: <span className="price">₦{project.price}</span></p>
              <p>Category: {project.category}</p>
              <p>Technology Stack:</p>
              <ul>
                {
                  project.tech_stack && project.tech_stack.map((tech,index) =>{
                    return<li key={index}>{tech}</li>
                  })
                }
              </ul>
              {!isLoading ? <button className="add-to-cart" onClick={()=>handleAddToCart()}>Add to Cart</button> :
                <BeatLoader className="loader" color="#ccc" /> 
              }
            </div>
          </div>
        </div></>:<p>Project May have been deleted.</p>}
      </div>
      {project !== null && (project.demo_link !== "" && project.demo_link !== null) &&<div className="preview">
        <h5>Video Preview</h5>
        <ProjectVideoPreview source={project.demo_link}/>
      </div>}
      <div className="related-items mb-4 p-2">
        { relatedProjects !== null && <><h2>Related Projects</h2>
            <div className="row">
              {relatedProjects.map((proj) => {
                return <div key={proj.id} className="col-md-3 col-sm-12 item">
                  <div>
                    <img src={CloudinaryImageUrl(proj.image_url)} alt="" className="img-fluid" />
                    <button className="add-to-cart">Add to cart</button>
                  </div>
                  <h6>{proj.title}</h6>
                </div> 
              })}
            </div>
          </>
        }
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div `
  .card-img{
    height:350px;
  }
  .price{
    font-weight:800;
  }
  .add-to-cart{
    padding:10px 25px;
    text-decoration:none;
    font-weight:700;
    border:none;
    background:#211E3B;
    color:#fff;
    transition:1s ease all;
    border-radius:5px;
    text-transform:uppercase; 
  }
  .add-to-cart:hover{
    background:#99ACF9;
  }
  .loader{
    max-width:180px !important;
  }
  .previewImg{
    width:100%;
    height:180px;
    aspect-ratio:3/4;
  }
  .related-items .item{
    position: relative;
    transition:1s ease all;
    margin-right:10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px 0px;
    .add-to-cart{
      display:none;
      width:85%;
      border-radius:5px;
      text-transform:uppercase;
    }
  }
  .related-items .item:hover{
    .add-to-cart{
      display:block;
      background:#211e3bd5;
      position:absolute;
      bottom:40px;
      left:28px;
    }
    .add-to-cart:hover{
      background:#63a5fbd5;
    }
    
    // padding:5px;
  }
`;