import React, { useState } from 'react'
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import {Axios} from "../../utils/middleware/config";
import {useForm } from "react-hook-form";
import PulseLoader  from "react-spinners/PulseLoader"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Login = () => {

  const [isLoading, setIsLoading] = useState(false);
  const {register,handleSubmit,reset, formState:{errors}} = useForm({
    defaultValues:{
      email:'',
      password:''
    }
  });
  
  const handleLogIn = async(data) =>{
    setIsLoading(true);
    const payload ={
      email:data.email,
      password:data.pass
    }

    try {
      const result = await Axios.post('/user/login', payload);
      console.log(result)
      if(result){
        const {userDetails, roles} = result.data.data;
        const user = {
          id: userDetails.id,
          name:userDetails.name,
          email : userDetails.email,
          role: roles[0]
        }
        sessionStorage.setItem('isOnline',JSON.stringify(user));
        setTimeout(() => {
          window.location.href ='admin/dashboard'
        }, 2000);
      }
    } catch (error) {
      
      let message = error.message;
      let validationError = null;

      if (error.response?.data) {
        message = error.response.data.message || message;
        validationError = error.response.data.data ? error.response.data.data[0] : null;
      }
      setTimeout(() => {
        setIsLoading(false);

        if(validationError){
          toast.error( validationError.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            theme: "colored",
          });
          return;
        }
      
        toast.error( message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: "colored",
        });
        reset();
      }, 2000);
      
    }
    
  }
  return (
    
    <Wrapper>
      <div className=''>
        <nav className="navbar navbar-expand-lg  border-bottom">
          <div className="container-fluid">
            <NavLink className="navbar-brand" to="/admin/dashboard">
              <img src={require("../../utils/Img/Edited.png")} alt="" className="img-fluid" /></NavLink>
          </div>
        </nav>
        <form onSubmit={handleSubmit(handleLogIn)} >
          <h2 className='text-center'>Login to your profile</h2>
          <div className="social-icon text-center">
            <i className="fab fa-google fa-2x fa-fw"></i>
            <i className="fab fa-facebook fa-2x fa-fw"></i>
            <i className="fab fa-github-square fa-2x fa-fw"></i>
          </div>
          <hr />
          <div className="">
            <div className="form-group my-3">
              <input type="email" name="email" placeholder='Email Address' className="form-control" 
                {...register('email',{required:true})}
              />
              {errors.email  && <span className='text-danger'>Email Address is required.</span>}
            </div>
            <div className="form-group my-3">
              <input type="password" name="pass" placeholder='Password' className="form-control" 
                {...register('pass',{required:true})}
              />
              {errors.pass  && <span className='text-danger'>Password is required.</span>}
              <a href='/reset-password' className="mt-0 text-danger forgortpwd">Forgot Password?</a>
            </div>
            {!isLoading ? <button className="btn">LogIn</button> :
              <PulseLoader color='#2B2B45' className="text-center mt-4" speedMultiplier={0.9} />
            }
          </div>
          <ToastContainer/>
        </form>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div `
  form{
    width:25rem;
    margin:1% auto 7% auto;
    padding:15px;

    @media screen and (max-width:480px){
     width:90%;
    }
    .social-icon{
     margin:10px 0 5px 0;
    }
    .forgortpwd{
      font-weight:700;
      cursor:pointer;
      float:right;
      text-decoration:none;
    }
    .btn{
      margin-top:15px;
      background:#8199F7;
      color:#fff;
      font-weight:800;
      width:100%;
      text-transform:uppercase;
    }
  }
`;