import React, { useState } from 'react';
import styled from 'styled-components';
// import {Axios} from '../../utils/middleware/config';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';


export const ResearchHero = ({modal, showProject}) => {
  const [projectTitle, setProjectTitle] = useState('')
  
    const search = async(title) => {
      if(!title){
        // toast.error('Invalid Project Topic', {
        //   position: "top-right",
        //   autoClose: 2000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   progress: undefined,
        //   theme: "colored",
        // });
        return
      }

      // showProject(title);
      // try {
      //   const proj = await Axios.get(`/project/${title}`)
      //   console.log(proj)
      //   showProject([proj.data])
      // } catch (error) {
      //   toast.error('Project Not Found!', {
      //     position: "top-right",
      //     autoClose: 2000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     progress: undefined,
      //     theme: "colored",
      //   });
      //   console.log(error.response)
      // }
      
    };

  return (
    <Wrapper>
      <div className="text-center " id="hero_section">
        <h1 className="mb-0 ">Academic Research Topics and Materials</h1>
        <p className="mt-0">Get complete Final year projects for B.Sc, HND, OND, NCE, M.Sc and Ph.D all in one place.</p>

        <div className="input-group  mx-auto">
          <input type="text" onChange={(e) => setProjectTitle(e.target.value)} className="form-control" placeholder='Enter Project Topic'/>
          <button className="btn btn-secondary btn-find" type="button" onClick={()=>search(projectTitle)}><i className="fa-solid fa-magnifying-glass"></i> Find Project</button>
        </div>
        {/* <ToastContainer/> */}
        <div className="">
          <h4>Can't Find your Topic?</h4>
          <button className="btn btn_topic"  data-bs-toggle="modal" data-bs-target={modal}>Submit Topic</button>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div `
  #hero_section {
    background: linear-gradient(90deg, rgba(126,214,250,0.723) 0%, rgba(176,98,245,0.8) 100%),url(${require("../../utils/Img/research/research.jpg")});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 400px;
    color: #fff;
    padding:25px;

    @media screen and (max-width:480px){
      padding:10px;
    }

    h1{
      font-weight:800;
    }
    .input-group{
      margin-top:8%;
      width:40%;

      @media screen and (max-width:768px){
        width:65%;
      }
      @media screen and (max-width:480px){
        margin-top:15%;
        width:90%;

        .btn-find{
          font-size:14px;
          font-weight: bold;
          padding:5px;
          width:40%;
        }
      }
      @media screen and (max-width:380px){
        margin-top:25%;
        width:100%;
      }
    }
    h4{
      margin:20px 0 5px 0;
    }
    .btn_topic{
      background:#350165;
      color:#fff;
      font-weight:700;
      padding:5px 25px;
    }
  }
`;