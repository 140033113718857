import React from 'react';
import styled from 'styled-components';
import { ContactUsForm } from '../components/ContactUsForm';



export const Contact = () => {
  return (
    <Wrapper>
      <div className="container-fluid wrap">
    
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <h1 className="brand mt-1">Contact Us</h1>
              <p>
                Give us a call or drop by anytime, we endeavour to answer all enquiries within 24 hours on business days. 
                We will be happy to answer your questions.
              </p>
              <div className="mt-2">
                <h5>Enugu Office</h5>
                <address>
                  <p>
                    <i className="fa fa-map-marker c_icon"></i> Upper Nike Road,<br />
                    Abakpa-Nike, Enugu State.<br />
                    <i className=" fa fa-phone-alt mr-2 c_icon"></i>07034301877<br />
                    <a href='mailto:info@xpressdreams.com.ng'><i className="fa fa-envelope mr-2 c_icon"></i>info@xpressdreams.com.ng</a>
                  </p>
                </address>
              </div>
              <hr/>
              <div>
                <h5>Lagos Office</h5>
                <address>
                  <p>
                    <i className="fa fa-map-marker c_icon"></i> Sapele Street Oriokuta Estate,<br />
                    Ikorodu, Lagos State.<br />
                    <i className=" fa fa-phone-alt mr-2 c_icon"></i>07034301877<br />
                    <a href='mailto:info@xpressdreams.com.ng'><i className="fa fa-envelope mr-2 c_icon"></i>info@xpressdreams.com.ng</a>
                  </p>
                </address>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <ContactUsForm />
            </div>
            
          </div>
        </div>
        <div className="map">
          <div className="gmap_canvas  embed-responsive embed-responsive-4by canvas">
            <iframe width="100%" height="90%" id="gmap_canvas" src="https://maps.google.com/maps?q=upper-nike%20Road%20Enugu%2C%20Nigeria&t=&z=13&ie=UTF8&iwloc=&output=embed" title='xpressAddress'  frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0">
            </iframe>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div `
  .wrap{
    h1{
      font-weight:800;
    }
    p a{
      text-decoration: none;
      color:inherit;
    }
    i{
      margin-right:6px;
      color:#8F49D5;
    }
  }
`;
