import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export const Testimony = () => {

  const settings ={
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrow:true,

    responsive: [{
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }]
  }

  return (
    <Wrapper>
      <div className="container">
        <div id="clients">
          <div className="text-center mb-4 mt-3">
            <h1 className="mb-0">Testimony from Our Happy Students</h1>
            <p className="mt-0">What students are saying about Xpress Dreams Academic Research</p>
          </div>
          <Slider {...settings} className='slick'>
            <div className="mx-2" >
              <figure>
                <blockquote className="blockquote mb-4">
                  <p>
                    <i className="fas fa-quote-left fa-lg text-warning me-2"></i>
                    <span className="font-italic">
                      It's nice doing business with you guys
                        always delivering within time frame
                    </span>
                  </p>
                </blockquote>
                <figcaption className="blockquote-footer">
                  Abubakar Hamza Yusuf <cite title="Source Title">ABU Zaria</cite>
                </figcaption>
              </figure>
            </div>
            <div className="mx-2" >
              <figure>
                <blockquote className="blockquote mb-4">
                  <p>
                    <i className="fas fa-quote-left fa-lg text-warning me-2"></i>
                    <span className="font-italic">
                      You guys rock! Thank you for making it painless, pleasant and most of all hassle free! I wish I would have thought of it first. 
                      I am really satisfied with my first service.
                    </span>
                  </p>
                </blockquote>
                <figcaption className="blockquote-footer">
                  Lucy Chioma Nwosu <cite title="Source Title">Enugu State University of Science and Technology (ESUT)</cite>
                </figcaption>
              </figure>
            </div>
            <div className="mx-2" >
              <figure>
                <blockquote className="blockquote mb-4">
                  <p>
                    <i className="fas fa-quote-left fa-lg text-warning me-2"></i>
                    <span className="font-italic">
                      Xpress Dreams proved to be a professional service provider, I appreciate their proactive approach and 
                      ability to suggest improvements to a prospective solution on both architectural and business levels.
                    </span>
                  </p>
                </blockquote>
                <figcaption className="blockquote-footer">
                  Ayomide Olaniyi <cite title="Source Title">Lagos State University</cite>
                </figcaption>
              </figure>
            </div>
            <div className="mx-2" >
              <figure>
                <blockquote className="blockquote mb-4">
                  <p>
                    <i className="fas fa-quote-left fa-lg text-warning me-2"></i>
                    <span className="font-italic">
                      Writing my thesis was a breeze, thanks to Xpress Dreams.
                      You guys are amazing set of individuals. Kudos!
                    </span>
                  </p>
                </blockquote>
                <figcaption className="blockquote-footer">
                  Amaka Okonkwo <cite title="Source Title">University of Nigeria Nsukka.</cite>
                </figcaption>
              </figure>
            </div>
            <div className="mx-2" >
              <figure>
                <blockquote className="blockquote mb-4">
                  <p>
                    <i className="fas fa-quote-left fa-lg text-warning me-2"></i>
                    <span className="font-italic">
                      Your support service is awesome, I was able to complete my project and had my defence successfully.
                      Thanks to your Team of Writers and developers.
                    </span>
                  </p>
                </blockquote>
                <figcaption className="blockquote-footer">
                  Micheal Adewale <cite title="Source Title">Lagos State University</cite>
                </figcaption>
              </figure>
            </div>
          </Slider>
        </div>
      </div>

    </Wrapper>
  )
}

const Wrapper = styled.div `
  h1{
    font-weight:800;
  }
  @media screen and (max-width:480px){
    .slick{
      display:flex;
      flex-direction:column;
    }
  }
  figure{
    padding:10px;

    p{
      font-size:16px;
    }
  }
`;