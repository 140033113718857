import { Cloudinary } from "@cloudinary/url-gen";
import { AdvancedImage } from '@cloudinary/react';
import { focusOn } from '@cloudinary/url-gen/qualifiers/gravity';
import {FocusOn} from '@cloudinary/url-gen/qualifiers/focusOn';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { auto } from '@cloudinary/url-gen/qualifiers/quality';
import { byRadius } from '@cloudinary/url-gen/actions/roundCorners';
import { format } from '@cloudinary/url-gen/actions/delivery';
import { png } from '@cloudinary/url-gen/qualifiers/format';


const imageSizes = {
  'profile':{
    height:100
  },
}

const CloudinaryImageUrl = (publicId) =>{
  const cld = new Cloudinary({
    cloud:{
      cloudName: 'xpressdreams'
    }
  });
  const img = cld.image(publicId);
  return img.toURL();
}

const XpressImage = ({publicId,size,type}) => {

  const cld = new Cloudinary({
    cloud:{
      cloudName: 'xpressdreams'
    }
  });
  let img;
  try {
    img = cld.image(publicId);
    type ==='product'? img.resize(fill(auto(),size)) :
    type ==='project'? img.resize(fill(500,350)):
    type ==='profile'? img.resize(fill(auto(),size).gravity(focusOn(FocusOn.face()))).roundCorners(byRadius(100)):
    img.resize(fill(500,250),format(png)).quality('auto');
  } catch (error) {

  }
  
  return (
    <>
      <AdvancedImage cldImg={img} />
    </>
  )
}
export {imageSizes, CloudinaryImageUrl, XpressImage}