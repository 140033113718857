import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Axios } from '../../utils/middleware/config';
import { CloudinaryImageUrl } from '../../utils/helper';


export const BlogPageLayout = (props) => {

  const [recentPost, setRecentPost] = useState([]);

  const loadRecentPost = async () =>{
    try {
      const {data} = await Axios.get(`blogs/recent?size=6`);
      if(data.success){
        setRecentPost(data.data);
      }
    } catch (error) {
      
    }
  }

  useEffect(() =>{
    if(recentPost.length < 1){
      loadRecentPost();
    }
  },[recentPost])
  return (
    <Wrapper>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-9 col-sm-12">
            <main>
              {
                props.children
              }
            </main>
            
          </div>

          <div className="col-md-3 col-sm-12">
            <aside>
              <div className="search-post">
                <div className="input-group  mx-auto">
                  <input type="text" className="form-control" placeholder='Search'/>
                  <button className="btn btn-secondary btn-find" type="button"><i className="fa-solid fa-magnifying-glass"></i></button>
                </div>
              </div>
              
              <div>
                <h3>Category</h3>
                <ul className="category_list">
                  <li>Technology (6)</li>
                  <li>Design (2)</li>
                  <li>Software Development (10)</li>
                  <li>Games (4)</li>
                  <li>Web3 (1)</li>
                  <li>Artificial Intelligence (6)</li>
                </ul>
              </div>
              <div className="recent-post">
                {recentPost.length>0 && <h3>Recent Post</h3>}
                {
                  recentPost.map((post) =>{
                    return <div className="recent-items d-flex gap-3 align-items-center my-2" key={post.id}>
                      <div>
                        <img src={CloudinaryImageUrl(post.image_url)} alt="" className="img-fluid" />
                      </div>
                      <div>
                        <h6>{post.title}</h6>
                        <p className='text-muted'>{new Date(post.created_at).toDateString()}</p>
                      </div>
                    </div>
                  })
                }
                
              </div>

              <div className="tags">
                <h3>Tag</h3>
                <div className="items">
                  <span>C#</span>
                  <span>AI</span>
                  <span>Python</span>
                  <span>JavaScript</span>
                  <span>Game Development</span>
                  <span>ReactJs</span>
                  <span>Angular</span>
                  <span>RESTful API</span>
                  <span>React Native</span>
                  <span>Technology</span>
                  
                </div>
              </div>
              <iframe title='vtpass' src="https://vtpass.com/widget/WTBjNWQxcFhXbmxaVnpWcVlWaE5NR050Vm1oaVJVSnVZbGRHY0dKRE5XcGlNakE5" frameBorder="0"   height="415" ></iframe>
            </aside>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div `
 
  aside{
    margin:4% 0;
    h3{
      text-transform:uppercase;
      font-weight:700;
    }
    .category_list{
      list-style:none;
      padding-left:15px;
      li{
        margin-left:0;
        text-transform:uppercase;
        font-weight:500;
        color:#3f3e3ecc;
        cursor:pointer;
        transition: 1s ease all;
      }
      li:hover{
        margin-left:10px;
        color:#91ADFB;
        font-weight:800;
      }
    }
    .recent-post{
      img{
        max-width:150px;

        @media screen and (max-width:768px){
          width:97%;
        }
      }
      h6{
        font-size:14px;
      }
      p{
        font-size:12px;
      }
      .recent-items{
        @media screen and (max-width:768px){
          flex-direction: column !important;
          gap:1px !important;
        }
      }
    }
    .tags{
      .items{
        display:flex;;
        gap: 9px;
        flex-flow:row wrap;
        margin-bottom:5%;

        span{
          padding:10px;
          font-weight:600;
          background:#706e8287;
          color:#fff;
          transition:1s all ease;
        }
        span:hover{
          background:#211E3B;
          border-radius:5px;
          cursor:pointer;
        }
      }
    }
  }
`;