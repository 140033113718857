import React, { useState } from 'react';
import styled from 'styled-components';
import { Projects } from '../components/researchComponents/Projects';
// import { ResearchHero } from '../components/researchComponents/ResearchHero';
import { ResearchTopicForm } from '../components/researchComponents/ResearchTopicForm';
import { Testimony } from '../components/researchComponents/Testimony';
import { HireWriterForm } from '../components/researchComponents/WriterForm';
import {Axios} from '../utils/middleware/config';



export const Research = () => {

  const [email, setEmail] = useState('');

  const subscribe = async(subscriberEmail) =>{
    try {
      if(email === ""){
        return;
      }
      const {data} = await Axios.post('/mailing-list',{email:subscriberEmail});
      if(data.data){
        setEmail('');
      }
    } catch (error) {
      console.log(error.response)
    }
  }
  return (
    <Wrapper className="container-fluid">
      {/* <ResearchHero modal="#researchTopicModal"/> */}
      <Projects topicModal="#researchTopicModal"/>

      <div id="hire" className="mb-5">
        <div className="hire">
          <div className="row no-gutters hire_row">
            <div className="col-md-7 col-sm-12">
              <img className="img-fluid" src={require("../utils/Img/research/stressed.jpeg")} alt=''/>
            </div>
            <div className="col-md-5 col-sm-12">
              <div className="card-body">
                <p className="card-text">
                  Research means you don't know but willing to find out, its a welcome attitude towards change, an effort to do thing better.
                </p>
                <h4>Need help working on your research?</h4>
                <p>
                  Our research teams are available at low cost.
                </p>
                <button className="btn btn-hire" data-bs-toggle="modal" data-bs-target="#hireWriterModal">Hire a Writer</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="how_it_work" className="container-fluid">
        <div className="text-center">
          <h1 className="">How it works</h1>
          <p>
            Downloading a project material from Xpress Dreams is just very simple<br/>
            Follow the 3-steps to proceed.
          </p>
        </div>
        <div className="row container">
          <div className="col-md-4 col-sm-12 ">
            <div className="item_wrap">
              <i className="fa-solid fa-magnifying-glass"></i>
            </div>
            <div className="text-center">
              <h4>Search Project Topic</h4>
              <p>Search for your project topic on this website. Click on "Get Project" to get the full material.</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 ">
            <div className="item_wrap">
              <i className="fa-solid fa-circle-dollar-to-slot"></i>
            </div>
            <div className="text-center">
              <h4>Place Order</h4>
              <p>
                Pay your project service charge using the bank account details listed on the Checkout page on this website.
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 ">
            <div className="item_wrap">
              <i className="fa-solid fa-download"></i>
            </div>
            <div className="text-center">
              <h4>Download Project</h4>
              <p>
                Click On "Download now" button to get your full project materials along with the accompanying freebies.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="online_library">
        <div className="text-center">
          <div>
            <h1 className="mb-0">Largest Online Academic Research Library</h1>
            <p className="mt-0">
              We maintain a database of project resources for all Computer Science and Engineering relate domain.
            </p>
          </div>
          <div className="row my-5">
            <div className="col-md-3 col-sm-6">
              <div>
                <h4 className="mb-0">3500+</h4>
                <p>Project Materials</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div>
                <h4 className="mb-0">1500+</h4>
                <p>Students</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div>
                <h4 className="mb-0">8500+</h4>
                <p>Downloads</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-12">
              <div>
                <h4 className="mb-0">120+</h4>
                <p>Writers</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Testimony />
      
      <div id="news_letter">
        <div className="row container">
          <div className="col-md-7 col-sm-12">
            <div>
              <h4>Subscribe to our Newsletters</h4>
              <p>Stay up to date with the latest project news, analysis and cutting edge report on project research works and latest technology</p>
            </div>
          </div>
          <div className="col-md-5 col-sm-12">
            <form className="input-group mb-0" onSubmit={(e) => {e.preventDefault();}} method='post'>
              <input type="email" className="form-control" value={email} required placeholder="Email Address" aria-label="Email Address" aria-describedby="new_sub" 
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className="input-group-append">
                <button className="input-group-text" id="new_sub" onClick={() => subscribe(email)}>Send</button>
              </div>
            </form>
            <div className="mt-0">
              <p className="lead mt-0 ">*your email will not be published.</p>
            </div>
          </div>
        </div>
      </div>

      {/* project Request Modal */}
      <div className="modal fade" id="researchTopicModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="researchTopicModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="researchTopicModalLabel">Project Request</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" id='proj_req_close' aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <ResearchTopicForm />
            </div>
          </div>
        </div>
      </div>

       {/* Hire writer Modal */}
       <div className="modal fade" id="hireWriterModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="hireWriterModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="hireWriterModalLabel">Hire a Seasoned Writer</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" id='writer_req_close' aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <HireWriterForm closeBtn="writer_req_close" />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}


const Wrapper = styled.div `

  .hire{
    clear:both;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    background: #d4d3d1;

    img{
      width:100%;]
    }
    .card-body{
      padding:10px;
      margin-top:25% !important;

      @media screen and (max-width:768px){
        margin-top:0 !important;
        padding:2px;
      }
      @media screen and (max-width:480px){
        padding:15px;
      }

      .btn-hire{
        background:#256017;
        color:#fff;
      }
      .btn-hire:hover{
        background:#284720;
      }
    }
  }
  #how_it_work {
    padding: 2% 0;
    margin: 1% 0;

    h1{
      font-weight:800;
    }
  }

  #how_it_work .item_wrap {
    width: 80px;
    height: 80px;
    margin: 20px auto;
    border: 3px dashed #fb5252;
    color: #fb5252;
    border-radius: 50%;
    font-size: 2em;
    text-align: center;
    padding: 10px 0;
  }

  #online_library {
    height: 400px;
    padding: 5% 0 0 0;
    background: radial-gradient(circle, rgba(63,94,251,0.7964227927499125) 29%, rgba(252,70,107,0.8160306358871674) 97%), url(${require("../utils/Img/research/graduates.jpeg")});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: #fff;

    @media screen and (max-width:480px){
      padding:2%;
      height:max-content;
      
      h4{
        font-size:32px;
        font-weight:800;
      }
    }
  }

  #news_letter {
    background: rgb(193, 195, 204,0.6);
    padding: 25px 15px 10px 15px;
    width: 100%;

    .lead{
      font-size:14px;
    }
    #new_sub{
      font-weight:800;
      border-bottom-left-radius:0;
      border-top-left-radius:0;
      transition:1s ease all;
    }
    #new_sub:hover{
      background:#1e1c1ecc;
      color:#E9ECEF;
     
    }
    .icons{
      font-size:2.1em;
      cursor:pointer;
      transition: all ease 1s;
    }
  }

  #news_letter .icons:hover {
    color: #6973BA;
    transform: scale(1.2);
  }
`;