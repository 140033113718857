import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {Axios, AxiosForm}from '../../utils/middleware/config';
import { useForm } from 'react-hook-form';
import { BeatLoader } from 'react-spinners';
import { AdminLayout } from './AdminLayout';
import { XpressImage } from '../../utils/helper'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const Courses = () => {

  const {register, handleSubmit, reset,formState:{errors}} = useForm();
  const [studentCourses, setStudentCourses] = useState([]);
  const [courseToEdit, setCourseToEdit] = useState({});
  const [update, setUpdate] = useState(false);
  const [imgUrl, setImgUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);


  const imageUpload = () => document.getElementById('image').click();
  const handleImageUpload = (e) =>{
    setImgUrl(e.target.files[0]);
  }
  const handleFormSubmit = async (forminput) =>{
    const courseData = new FormData();
    console.log(forminput);
    courseData.append('title',forminput.title);
    courseData.append('description', forminput.description);
    courseData.append('image',imgUrl);

    setIsLoading(true)
    try {
      const {data, success} = await AxiosForm.post(`/course`,courseData);
      console.log(data)
      if(success){
        toast.success("Post Published",{
          position:"top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          setIsLoading(false);
          loadData();
          setImgUrl(null);
          reset();
          document.getElementById('view-courses-tab').click();
        }, 3000);
        return;
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }
  const loadData = async () =>{
    const {data} = await Axios.get(`/courses`);
    if(data){
      const {course} = data.data;
      setStudentCourses(course);
    }
  }

  const editCourse = (courseId) => {
    let course = studentCourses.find(x => x.id === courseId);
    setCourseToEdit(course);
    console.log(imgUrl)
    setUpdate(true);
    document.getElementById('add-course-tab').click();
  }
  const deleteCourse = async (courseId) => {
    const {data} = await Axios.delete(`/course/${courseId}`);
    if(data){
      console.log(data.data);
      loadData();
      document.getElementById('view-courses-tab').click();
    }
  }

  useEffect(() =>{
    loadData();
  },[])
  return (
    <Wrapper>
      <AdminLayout>
        <nav className='ms-4'>
          <div className="nav tabs" id="nav-tab" role="tablist">
            <button className="nav-link active" id="add-course-tab" data-bs-toggle="tab" data-bs-target="#add-course" type="button" role="tab" aria-controls="add-course" aria-selected="true">Add New Course</button>
            <button className="nav-link" id="view-courses-tab" data-bs-toggle="tab" data-bs-target="#view-courses" type="button" role="tab" aria-controls="view-courses" aria-selected="false">View Courses</button>
          </div>
        </nav>

        <div className="tab-content" id="nav-tabContent">
          <div className="tab-pane fade show active" id="add-course" role="tabpanel" aria-labelledby="add-course-tab" tabIndex="0">
            <div className="mt-2 container">
              {!update?
                <div>
                  <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className="row">
                      <div className="col-md-5">
                        <div className="form-group my-1">
                          <input type="text" name="title" placeholder='Course Title' className="form-control" 
                            {...register('title',{required:true})}
                          />
                          {errors.title && <span className='text-danger'>Course Title is required.</span>}
                        </div>
                        <div className="form-group">
                          <textarea name="description" id="" cols="30" rows="4 " className="form-control"
                            {...register('description',{required:true})}
                          ></textarea>
                          {errors.description && <span className='text-danger'>Description is required.</span>}
                        </div>
                        <div className="form-group">
                          <input type="file" name="image" id="image" accept='image/*' onChange={(e)=>handleImageUpload(e)} className="form-control d-none" 
                            // {...register('image',{required:true})}
                          />
                          <i className="fas fa-image fa-2x fa-fw" onClick={imageUpload}></i>
                        </div>
                        <div>
                        {(!isLoading && imgUrl !==null) &&<button className="btn-primary-block">Upload Course</button>}
                        {isLoading && <BeatLoader color='#ccc' className='loader' speedMultiplier={0.9}/>}
                        </div>
                      </div>
                      <div className="col-md-7">
                        <img src={imgUrl !== null?URL.createObjectURL(imgUrl): ""} alt="" className="img-fluid" />
                      </div>
                    </div>
                  </form>
                </div> :

                <div>
                  <form>
                    <div className="row">
                      <div className="col-md-5">
                        <div className="form-group my-1">
                          <input type="text" name="title"  value={courseToEdit.title} placeholder='Course Title' className="form-control" />
                        </div>
                        <div className="form-group">
                          <textarea name="description" id="" value={courseToEdit.description} cols="30" rows="4 " className="form-control"></textarea>
                        </div>
                        <div className="form-group">
                          <input type="file" name="image" id="image" accept='image/*' onChange={(e)=>handleImageUpload(e)} className="form-control d-none" />
                          <i className="fas fa-image fa-2x fa-fw" onClick={imageUpload}></i>
                          <button className="btn-primary-block mt-2">Update Course</button>
                        </div>
                      </div>
                      <div className="col-md-7">
                        <img src={imgUrl !== null?URL.createObjectURL(imgUrl): ""} alt="" className="img-fluid" />
                        {imgUrl ===null && <XpressImage publicId={courseToEdit.image_url} />}
                      </div>
                    </div>
                  </form>
                </div>
              }
            </div>
            <ToastContainer/>
          </div>

          <div className="tab-pane fade" id="view-courses" role="tabpanel" aria-labelledby="view-courses-tab" tabIndex="0">
            <div className="mt-4 container-fluid">
              {studentCourses.length > 0?<div className='row'>
                {
                  studentCourses.map((course) =>{
                    return <div className='col-md-6 ' key={course.id}>
                      
                      <div className='course'>
                        <XpressImage publicId={course.image_url}  className='d-block w-100 px-0'/>
                        <h5 className='ms-3 mt-2'><a href='/'>{course.title}</a></h5>
                        <div className='actions'>
                          <i className="fas fa-edit fa-lg edit"dataid={course.id}
                            onClick={(e)=>editCourse(e.currentTarget.getAttribute('dataid'))}
                          ></i>
                          <i className="fas fa-trash fa-lg fa-fw delete" dataid={course.id}
                            onClick={(e)=>deleteCourse(e.currentTarget.getAttribute("dataid"))}
                          ></i>
                        </div>
                      </div>
                    
                    </div>
                  })
                }
                </div> :
                <><p className="lead mt-2">No Course found!</p>
                </>
              }
            </div>
          </div>
        </div>
      </AdminLayout>
      
    </Wrapper>
  )
}

const Wrapper = styled.div `
  form{
    padding:5px;
  }
  .tabs{

    .nav-link{
      border:none;
      margin: 0 5px;
      background:inherit;
      font-weight:bold;
      padding:0;
    }
    .active{
      background:#91a5f9;
      color:#fff;
      padding:3px 10px;
      border-radius:5px;
    }
  }
  .course{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-bottom:4%;

    img{
      width:100%;
      height:auto;
    }
    
    a{
      color:#91a5f9;
      text-decoration:none;
      font-weight:800;
    }
    p{
      color:#000;
    }
  }
`;