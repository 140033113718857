import React from 'react'
import { Outlet, Navigate } from 'react-router-dom';
import { AdminNav } from '../components/adminComponent/AdminNav';


export const Dashboard = () => {

  let isAuthenticated = sessionStorage.getItem('isOnline');
  return isAuthenticated ? <><AdminNav/><Outlet /></> : <Navigate to="/login" />
}
