import React,{useState} from 'react'
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import {useForm } from "react-hook-form";
import {Axios} from '../../utils/middleware/config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ResetPassword = () => {

  const [isLoading, setIsLoading] = useState(false);
  const {register,handleSubmit,reset, formState:{errors}} = useForm();

  const handleLogIn = async (inputData) =>{
    setIsLoading(true);
    console.log(inputData);
    if(inputData.password !== inputData.confirmPassword){
      setTimeout(() => {
        toast.error( "Password do not match", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: "colored",
        });
        setIsLoading(false);
      }, 3000);
      
      return;
    }
    const payload = {
      email: inputData.email,
      newPassword: inputData.password
    }
    try {
      const {data} = await Axios.put(`/user/password-reset`,payload);
      if(data){
        console.log(data)
        setTimeout(() => {
          setIsLoading(false);
          window.location.href="/login";
        }, 3000);
      }
      setIsLoading(false);
    } catch (error) {
      let {message} = error.response.data;
      const validationError =  error.response.data.data ? error.response.data.data[0] : null;
      setTimeout(() => {
        setIsLoading(false);

        if(validationError){
          toast.error( validationError.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            theme: "colored",
          });
          return;
        }
        toast.error( message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: "colored",
        });
        reset();
      }, 3000);
    }
    
  }

  return (
    <Wrapper>
      <div className=''>
        <nav className="navbar navbar-expand-lg  border-bottom">
          <div className="container-fluid">
            <NavLink className="navbar-brand" to="/admin/dashboard">
              <img src={require("../../utils/Img/Edited.png")} alt="" className="img-fluid" /></NavLink>
          </div>
        </nav>
        <form onSubmit={handleSubmit(handleLogIn)} >
          <h2 className='text-center'>Reset your password</h2>
          
          <div className="">
            <div className="form-group my-3">
              <input type="email" name="email" placeholder='Email Addresss' className="form-control" 
                {...register("email",{required:true})}
              />
              {errors.email  && <span className='text-danger'>Email Address is required.</span>}
            </div>
            <div className="form-group my-3">
              <input type="password" name="password" placeholder='Password' className="form-control" 
                {...register("password",{required:true})}
              />
              {errors.password  && <span className='text-danger'>Password is required.</span>}
            </div>
            <div className="form-group my-3">
              <input type="password" name="confirmPassword" placeholder='Confirm Password' className="form-control" 
                {...register("confirmPassword",{required:true})}
              />
              {errors.confirmPassword  && <span className='text-danger'>Confirm Password is required.</span>}
              <a href='/login' className="mt-0 text-danger forgortpwd">Back to Login</a>
            </div>
            {!isLoading? <button className="btn">Reset Password</button>:
              <BeatLoader color='#ccc' className='text-center btn-loader' speedMultiplier={0.9} />
            }
          </div>
        </form>
        <ToastContainer />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div `
  form{
    width:25rem;
    margin:1% auto 7% auto;
    padding:15px;

    @media screen and (max-width:480px){
     width:90%;
    }
    .social-icon{
     margin:10px 0 5px 0;
    }
    .forgortpwd{
      font-weight:700;
      cursor:pointer;
      float:right;
      text-decoration:none;
    }
    .btn{
      margin-top:15px;
      background:#8199F7;
      color:#fff;
      font-weight:800;
      width:100%;
      text-transform:uppercase;
    }
    .btn-loader{
      margin-top:35px;
      background:#8199F7;
      border-radius:5px;
      padding:5px;
      font-weight:800;
      width:100%;
    }
  }
`;