import React from 'react';
import styled from 'styled-components';
import { CourseSignupForm } from '../components/careerComponents/CourseSignupForm';
import { Hero } from '../components/Hero';
// import { Talent } from '../components/careerComponents/Talent';


export default function Career() {
  return (
    <Wrapper className='container-fluid'>
      <Hero />
      {/* <Talent /> */}

      <div id="tutorial">
        <div>
          <div>
            <h1>Our Learning paths</h1>
            <p>
              We aim to build an ecosystem for young techies and startup companies that create value <br/>
              and make a difference while being up to date products with the latest technologies.
            </p>
          </div>
          <div className="row">
              <div className="col-md-4 col-sm-12">
                <div className="item">
                  <div >
                    <img className="card-img img-fluid" src={require("../utils/Img/career/productDesign.png")} alt='' />
                  </div>
                  <div className="card-body">
                    <h6>Product Management</h6>
                    <p>
                      Learn how to identify customer needs 
                      and the larger business objectives that a product or feature will fulfill, articulates 
                      what success looks like for a product, and build a team to turn that vision to a reality.
                    </p>
                    <button className="btn enroll_btn" data-bs-toggle="modal" data-bs-target="#courseRegModal">Enroll Now</button>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-12">
                <div className="item">
                  <div >
                    <img className="card-img img-fluid" src={require("../utils/Img/career/frontend.png")} alt='' />
                  </div>
                  <div className="card-body">
                    <h6>Front-end Web Development</h6>
                    <p>
                      Introduction to programming, git and version control, and JavaScript. 
                      Learn JavaScript design patterns and how to develop web apps using React and Angular.
                    </p>
                    <button className="btn enroll_btn" data-bs-toggle="modal" data-bs-target="#courseRegModal">Enroll Now</button>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-12">
                <div className="item">
                  <div >
                    <img className="card-img img-fluid" src={require("../utils/Img/career/backend.png")} alt='' />
                  </div>
                  <div className="card-body">
                    <h6>Back-end Web Development</h6>
                    <p>
                      Introduction to programming, git and version control, and JavaScript. 
                      Learn all about OOP and Regular Expressions, 
                      Databases, JavaScript design patterns and how to develop APIs using Node.
                    </p>
                    <button className="btn enroll_btn" data-bs-toggle="modal" data-bs-target="#courseRegModal">Enroll Now</button>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-12">
                <div className="item">
                  <div >
                    <img className="card-img img-fluid" src={require("../utils/Img/career/career_game.jpg")} alt=''/>
                  </div>
                  <div className="card-body">
                    <h6>Game Development Bootcamp</h6>
                    <p>Training bootcamp for beginner 
                      game developers that aims to take you from zero experience to creating and publishing android games on google play store.
                    </p>
                    <button className="btn enroll_btn" data-bs-toggle="modal" data-bs-target="#courseRegModal">Enroll Now</button>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-12">
                <div className="item">
                  <div >
                    <img className="card-img img-fluid" src={require("../utils/Img/career//UI.jpeg")} alt='' />
                  </div>
                  <div className="card-body">
                    <h6>UI/UX Design</h6>
                    <p>
                      Designing User Interface (UI), 
                      Crafting User Experience (UX), branding products, 
                      design thinking, building information architecture, 
                      copywriting for projects.
                    </p>
                    <button className="btn enroll_btn" data-bs-toggle="modal" data-bs-target="#courseRegModal">Enroll Now</button>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-12">
                <div className="item">
                  <div >
                    <img className="card-img img-fluid" src={require("../utils/Img/career/web3.png")} alt =""/>
                  </div>
                  <div className="card-body">
                    <h6>Web3</h6>
                    <p>
                      Get to be a part of the new wave of web3 by learning the basics of web development and how to develop DApps with Solidity.
                    </p>
                    <button className="btn enroll_btn" data-bs-toggle="modal" data-bs-target="#courseRegModal">Enroll Now</button>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>

      <div id="private_tutor" className="mx-auto">
        <div className="content">  
          <div>
            <h3>Need Mentorship and Private Coaching?</h3>
            <p>Leave your details and we’ll be in touch within 24 hours.</p>
          </div>
          <a href="/contact" className="btn_contact btn">Contact Us</a>
        </div>
      </div>

      <div id="student_map" className="my-5">
        <div className="testimony_div">
          <div>
            <img className="img-fluid" src={require("../utils/Img/career/map.png")} alt=''/>
          </div>
        </div>
      </div>

      {/* Hire Modal */}
      <div className="modal fade" id="courseRegModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="courseRegModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="courseRegModalLabel">Signup for Our Courses</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" id='courseCloseModal' aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <CourseSignupForm />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div `
  h1{
    font-weight:700;
  }
  #tutorial {
    background: #091636;
    color:#fff;
    padding: 45px;

    @media screen and (max-width:768px){
      padding:10px;
    }
  }
  #tutorial .item {
    width: 100%;
    height: 450px;
    background: #122045;
    margin: 20px 0;
    border-radius:8px;
    position: relative;

    h6{
      font-weight:700;
    }
    @media screen and (max-width:768px){
      height:510px;
    }
    @media screen and (max-width:480px){
      height:409px;
      font-size:14px;
    }
    
    .card-body{
      padding:15px;

      @media screen and (max-width:480px){
        padding:7px;
      }
    }
  }
  #tutorial .item img{
    height:200px;
    width:100%;
  }
  #tutorial .enroll_btn {
    background: linear-gradient(45deg, rgba(9,9,121,0.9000642493325455) 0%, rgba(216,84,254,0.9616888991924895) 100%);
    color:#fff;
    transition: ease;
    position: absolute;
    bottom:15px;
  }
  #tutorial .enroll_btn:hover {
    background: linear-gradient(45deg, rgba(34,20,132,0.8916608879880077) 1%, rgba(157,64,213,0.9084676106770834) 42%, rgba(34,20,132,0.8888597675398284) 100%);
  }

  #private_tutor{
    position: relative;
    margin-bottom:65px;
    padding:0 5%;
  }
  #private_tutor .content {
    position: relative;
    top: -25px;
    background: #fff;
    width: 90%;
    border-radius: 5px;
    padding: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  }
  #private_tutor .btn_contact{
    padding: 10px 35px;
    color:#fff;
    background:#0462bd;
    text-transform:uppercase;
    font-weight:700;
  }
  @media screen and (max-width:768px){
    #private_tutor .content{
      flex-direction:column;
    }

  }

  @media screen and (max-width:480px) {

    #private_tutor .content {
        width: 100%;
        padding: 25px;
        top:-15px;
    }

    #private_tutor .btn_contact {
        font-size: 18px;
        padding: 15px;
        width: 200px;
    }
  }
  #student_map .testimony_div{
    margin-top:3%;
  }

`;
