import React,{useState,useEffect} from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import {Axios,AxiosForm} from '../../../utils/middleware/config';
import { BeatLoader } from 'react-spinners';
import { AdminLayout } from '../AdminLayout';
import { ToastContainer, toast } from 'react-toastify';
import { imageSizes, CloudinaryImageUrl, XpressImage } from '../../../utils/helper';


export const Author = () => {
  
  const [authors, setAuthors] = useState([]);
  const {register,handleSubmit,reset, formState:{errors}} =useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [authorToUpdate,setAuthorToUpdate] = useState({});
  const [profileImg, setProfileImg ] = useState('')
  const [file, setFile] = useState(null);
  
  const formData = new FormData();

  const loadAuthors = async () =>{
    const {data } = await Axios.get(`blog/authors`);
    if( data){
      const {BlogAuthor} = data.data;
      console.log(data.data)
      if(BlogAuthor){
        console.log(BlogAuthor)
        setAuthors(BlogAuthor);
      }
    }
  }
  const uploadImage = () => document.getElementById('avatar').click();

  const handleImageUpload = (e) =>{

    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    if(authorToUpdate.id){
      setProfileImg(URL.createObjectURL(selectedFile));
    }
  }
  const editAuthor = (authorId) =>{
    console.log(authorId)
    let authorToEdit = authors.find(x => x.id === authorId);
    setAuthorToUpdate(authorToEdit);
    setProfileImg(CloudinaryImageUrl(authorToEdit.bio.avatar_url));
  }

  const deletAuthor = async (authorId) =>{
    const {data} = await Axios.delete(`/blog/authors/${authorId}`);
    if(data){
      loadAuthors();
    }
  }
 
  const handleSubmitForm = async (input) =>{
    setIsLoading(true);
    const bio = {
      about:input.about,
      facebook:input.facebook,
      twitter: input.twitter,
      linkedIn: input.linkedin,
      github: input.github
    }
    formData.append("image", file, "C:/Users/DELL/Downloads/profile_pix.jpg");
    formData.append('fullname',input.name);
    formData.append('email',input.email);
    formData.append('bio',JSON.stringify(bio));
    
    for (let pair of formData.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }
    try {
      const {data} = await AxiosForm.post(`/blog/author`,formData) 
      if(data){
        setTimeout(() => {
          reset();
          setIsLoading(false);
          // setCategoryName('');
          loadAuthors();
        }, 2000);
        return;
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error)
      const data = error.response ? error.response.data : undefined;
      const msg = data !== undefined? data.message : error.message;
      setIsLoading(false);
      toast.error(msg,{
        position:'top-right',
        delay:2000
      })
    }
  }
  
  const handleUpdateDataChange = (e) =>{
    const {name, value} = e.target;
    console.log(name)
    setAuthorToUpdate((prevData) =>({
      ...prevData, [name]:value
    }));
  }
  console.log(authorToUpdate)
  const handleAuthorUpdate= async () =>{
    setIsLoading(true);
    //let bio = {}
    try {
      // let uploadDate = {
      //   id:authorToUpdate.id,
      //   fullname:authorToUpdate.name,
      //   email: authorToUpdate.email,
      //   bio:bio
      // }
      console.log('updated', authorToUpdate)
      const {data} = await Axios.put(`/blog/author`,authorToUpdate) 
      if(data){
        console.log(data.data)
        setTimeout(() => {
          reset();
          setIsLoading(false);
          reset();
          loadAuthors();
        }, 2000);
        return;
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error)
      setIsLoading(false);
    }
  }
 
  useEffect(() =>{
    loadAuthors();
  },[]);

  return (
    <Wrap className=''>
      <AdminLayout>
        <div className="row gutter-1 container-fluid">
          <div className="col-md-4 col-sm-12">
            {!authorToUpdate.id  ? 
              <form onSubmit={handleSubmit(handleSubmitForm)} method='post' encType="multipart/form-data">
                <div className="form-group mb-2">
                  <input type="text"  name="name" className="form-control" placeholder='Full Name' 
                    {...register('name',{required:true})}
                  />
                  {errors.name && <span className='text-danger'>Full Name is required.</span>}
                </div>
                <div className="form-group">
                  <input type="email" name="email" placeholder='Email Address' className="form-control" 
                    {...register("email",{required:true})}
                  />
                  {errors.email && <span className='text-danger'>Email Address is required.</span>}
                </div>
                <div className="form-group mt-1">
                  <h6 className='mb-0'>Bio Details</h6>
                  <div className="form-group mb-1">
                    <input type="text" name="github" placeholder='Github' className="form-control" 
                      {...register("github",{required:false})}
                    />
                  </div>
                  <div className="form-group mb-1">
                    <input type="text" name="linkdin" placeholder='LInkedIn' className="form-control"
                      {...register("linkedin",{required:false})}
                    />
                  </div>
                  <div className="form-group mb-1">
                    <input type="text" name="twitter" placeholder='Twitter' className="form-control" 
                      {...register("twitter",{required:false})}
                    />
                  </div>
                  <div className="form-group mb-1">
                    <input type="text" name="facebook" placeholder='Facebook Link' className="form-control" 
                      {...register('facebook',{required:false})}
                    />
                  </div>
                  <div className="form-group mb-1">
                    <textarea name="about" placeholder='About' cols="30" rows="2" className="form-control"
                      {...register('about',{required:true})}
                    ></textarea>
                    {errors.about && <span className='text-danger'>About is required.</span>}
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="">
                      <div className="form-group mb-1">
                        <input type="file" name="image" id='avatar' accept='image/*' onChange={(e) => handleImageUpload(e)} className="form-control d-none" 
                          // {...register('image')}
                        />
                        <i className="fas fa-image fa-lg fa-fw addImg" onClick={uploadImage}></i>
                      </div>
                    </div>
                    <div className="">
                      {file && <img src={URL.createObjectURL(file)} alt=""  className="img-fluid avatar" />}
                    </div>
                  </div>
                </div>
                <div className=''>
                  {!isLoading && <button type='submit' className="btn-primary-block">Profile Author</button>}
                  {isLoading && <BeatLoader className='loader' color='#ccc' speedMultiplier={0.9} />}
                </div>
              </form> :

              <form>
                <div className="form-group mb-2">
                  <input type="text"  name="bio.about" className="form-control" placeholder='Full Name' 
                    value={authorToUpdate.bio.about} onChange={(e)=> handleUpdateDataChange(e)}
                  />
                </div>
                <div className="form-group">
                  <input type="email" name="email" placeholder='Email Address' className="form-control" 
                    value={authorToUpdate.email} onChange={(e)=> handleAuthorUpdate(e)}
                  />
                </div>
                <div className="form-group mt-1">
                  <h6 className='mb-0'>Bio Details</h6>
                  <div className="form-group mb-1">
                    <input type="text" name="bio.github" placeholder='Github' className="form-control" 
                      value={authorToUpdate.bio.github} onChange={(e)=> handleAuthorUpdate(e)}
                    />
                  </div>
                  <div className="form-group mb-1">
                    <input type="text" name="bio.linkdin" placeholder='LInkedIn' className="form-control"
                      value={authorToUpdate.bio.linkedIn} onChange={(e) => handleAuthorUpdate(e)} />
                  </div>
                  <div className="form-group mb-1">
                    <input type="text" name="twitter" placeholder='Twitter' className="form-control" 
                      value={authorToUpdate.bio.twitter} onChange={(e)=> handleAuthorUpdate(e)}
                    />
                  </div>
                  <div className="form-group mb-1">
                    <input type="text" name="facebook" placeholder='Facebook Link' className="form-control" 
                      value={authorToUpdate.bio.facebook} onChange={(e) =>  handleAuthorUpdate(e)}
                    />
                  </div>
                  <div className="form-group mb-1">
                    <textarea name="about" placeholder='About' cols="30" rows="2" className="form-control"
                      value={authorToUpdate.bio.about} onChange={(e)=>  handleAuthorUpdate(e)}
                    ></textarea>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="">
                      <div className="form-group mb-1">
                        <input type="file" name="image" id='avatar' accept='image/*' onChange={(e) => handleImageUpload(e)} className="form-control d-none" />
                        <i className="fas fa-image fa-lg fa-fw addImg" onClick={uploadImage}></i>
                      </div>
                    </div>
                    <div className="">
                      <img src={profileImg} alt=""  className="img-fluid avatar" />
                    </div>
                  </div>
                </div>
                <div className=''>
                  {!isLoading && <button type='submit' onClick={handleAuthorUpdate} className="btn-primary-block">Update Author</button>}
                  {isLoading && <BeatLoader className='loader' color='#ccc' speedMultiplier={0.9} />}
                </div>
              </form>
            }
            <ToastContainer/>
          </div>
          
          <div className="col-md-8 col-sm-12 table-div">
            {authors.length > 0?<div className='row'>
              {
                authors.map((author) =>{
                  return <div className="author-div col-md-12 col-sm-12" key={author.id}>
                    <div className="details">
                      <div className='profile-div'> 
                        <XpressImage publicId={author.bio.avatar_url} type='profile' size={imageSizes.profile.height}/>
                        <p className='mb-0 mt-1'><span>Name: </span>{author.name}</p>
                        <p className='mt-0'><span>Email: </span>{author.email}</p>
                      </div>
                      <div>
                        <div className='bio-details'>
                          <p>{author.bio.about}</p>
                          <div className="d-flex justify-contents-between social-icons">
                            <a href={author.bio.github}><i className="fab fa-github fa-lg fa-fw"></i></a>
                            <a href={author.bio.linkedIn}><i className="fab fa-linkedin fa-lg fa-fw"></i></a>
                            <a href={author.bio.twitter}><i className="fab fa-twitter fa-lg fa-fw"></i></a>
                            <a href={author.bio.facebook}><i className="fab fa-facebook fa-lg fa-fw"></i></a>
                          </div>
                        </div>
                        <div className="actions">
                          <i className="fas fa-edit fa-sm edit"dataid={author.id}
                            onClick={(e)=>editAuthor(e.currentTarget.getAttribute('dataid'))}
                          ></i>
                          <i className="fas fa-trash fa-sm delete"dataid={author.id}
                            onClick={(e)=>deletAuthor(e.currentTarget.getAttribute('dataid'))}
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                })
              }
              <div className="pagination">
                <button className="prev">Previous</button>
                <button className="next">Next</button>
              </div>
              </div> :
              <><p className="lead mt-2">No Author found!</p>
              </>
            }
          </div>
        </div>
      </AdminLayout>
    </Wrap>
  )
}

const Wrap = styled.div `
  form{
    width:90%;
    padding:25px;
    margin:5% auto;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    @media screen and (width:768px){
      width:98%;
      padding:10px;
    }
  }
  .avatar{
    height:100px;
    width:auto;
    margin-bottom:5px;
  }
  .addImg{
    cursor:pointer;
    color:#7049ca;
  }
  .author-div{
    width:98%;
    margin:0 auto 15px auto;
  }
  .details{
    display:flex;
    margin-top:5px;
    padding:10px;
    position: relative;
    gap:2px;
    justify-content:space-between;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px 0px, rgba(17, 17, 26, 0.05) 0px 8px 32px 0px;
    
    @media screen and (max-width:600px){
      flex-direction:column;
      padding:5px
      margin:5px auto;
    }
  }
  
  .profile-div{
    p span{
      font-weight:700;
    }
  }
  .profileimg{
    width:100px;
    height:100px;
    border-radius:50%;
  }
  .bio-details{

    .social-icons{
      position:absolute;
      right:10px;
      bottom: 45px;

      @media screen and (max-width:600px){
        position:relative;
        right:2px;
        bottom:5px;
      }
    }
  }
  .actions{
    position: absolute;
    bottom: 5px;
    right: 10px;
  }
  
`;
