import React,{useEffect, useState} from 'react';
import styled from 'styled-components';
import { BlogAuthor } from './BlogAuthor';
import {BlogPostComment} from "./BlogPostComment";
import {BlogPageLayout} from "./BlogPagLayout";
import { useParams } from 'react-router-dom';
import {Axios} from '../../utils/middleware/config';
import { XpressImage } from '../../utils/helper';

export const BlogDetails = () => {

  let params = useParams();
  const slug = encodeURIComponent(params.slug);
 

  const [blogPost, setBlogPost] = useState([]);
  const [author, setAuthor] = useState([]);
  const [blogId, setBlogId] = useState('');

  const loadBlog = async (title) => {
    try {
      const {data} = await Axios.get(`/blog/article/${title}`);
      if(data){
        // console.log(data.data)
        const {author, authoremail, authorbio} = data.data[0];
        const blogAuthor ={author, email:authoremail,bio: authorbio};
        setAuthor(blogAuthor);
        setBlogId(data.data[0].id);
        setBlogPost(data.data);
      }
    } catch (error) {
      
    }
  }

  const likePost = async (postId) => {
    try {
      const {data} = await Axios.put(`/blog/${postId}/article/like`);
      if(data){
        // console.log(data.data)
        loadBlog(slug)
      }
    } catch (error) {
      
    }
  }

  useEffect(() =>{
    console.log(slug)
    loadBlog(slug);
  },[slug])

  return (
    <Wrapper>
      <BlogPageLayout>
      { blogPost.length > 0 ?<>
          <div className="mt-4 blog">
            {
              blogPost.map(blog =>{
                return(<div className='m-0 p-0' key={blog.id}>
                  <div className="postImg">
                    {/* <img src={require("../../utils/Img/blog1.jpg")} alt="" className="img-fluid" /> */}
                    <XpressImage publicId={blog.image_url} />
                  </div>
                  <div className="category d-flex gap-2">
                    {/* <span>Technology</span> */}
                    <span>{blog.category}</span>
                  </div>
                  <div className="info d-flex gap-3 ms-4">
                    <span className="date">{new Date(blog.created_at).toDateString()}</span>
                    <span><i className="fas fa-comment fa-sm fa-fw"></i>  {blog.totalcomments !== '0' ? blog.totalcomments : ''} Comments</span>
                    <span className='like'><i className="fas fa-heart  fa-sm fa-fw"></i> {blog.likes > 0 ? blog.likes : ''}</span>
                  </div>
                  <div className="px-4 py-2">
                    <h1 className="title">{blog.title}</h1>
                    <p>{blog.content}</p>
                  </div>
                  <span className='like-post ms-5' onClick={() =>likePost(blog.id)}><i className="fa fa-thumbs-up me-2" aria-hidden="true"></i>Like</span>
                  </div>
                )
              })
            }
            <hr />
          </div>
          <BlogAuthor blogAuthor ={author}/>
          <BlogPostComment blogId={blogId}/>
        </> :
        <div className='mt-5'>
          <p>Blog Post may have been deleted.</p>
        </div>
      }
      </BlogPageLayout>
      
    </Wrapper>
  )
}

const Wrapper = styled.div `
  .blog{
    .postImg{
      width:100%;

      img{
        width:100%;
        height: 450px;
        aspect-ratio:1/1;
      }
    }

    .category{
      position:relative;
      top:-12px;
      left:10px;
      span{
        background:#67dafb;
        color:#fff;
        padding:5px 10px;
        font-weight:700;
      }
    }
    .info{
      .like, .comment{
        cursor: pointer;
      }
      .like .fa-heart{
        color:red !important;
      }
    }
    .title{
      font-weight:800;
      font-size:29px;
      line-height:.9;
      margin-top:24px;
    }
    .like-post{
      i{
        color:#046202cc;
        font-weight:800;
        font-size:2em;
        transition: 1s ease all;
      }
      cursor:pointer;
    }
    .like-post:hover i{
      transform: scale(1.1);
    }
  }
`;