import React,{useState, useEffect}  from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const AdminLayout = (props) => {

  const [show, setShow] = useState(true);

  const toggler = (props) =>{ 
   setShow(!show)
  }
  const handleLogout = () =>{
    sessionStorage.removeItem("isOnline");
    window.location.href = "/login"
  }

  useEffect(()=>{
    window.addEventListener('resize', () => {
      if (window.innerWidth < 768) {
        if(show){setShow(!show)}
      }
    });
  })
  return (
    <AdminWrapper className=''>
      <div className="container-div">
        <div className={show?"sidebar show-sidebar":"sidebar"} id="sidebar">
          <img src={require("../../utils/Img/Edited.png")} alt='' className="img-fluid" />
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <i className="fas fa-home fa-sm"></i>
              <NavLink className="navlink" activeclass='active' aria-current="page" to="/admin/dashboard/home">Home</NavLink>
            </li>
            <li className="nav-item">
              <i className="fas fa-tag fa-sm fa-fw"></i>
              <NavLink className="navlink" activeclass='active' to="/admin/dashboard/orders">Order</NavLink>
            </li>
            <li className="nav-item">
              <i className="fa-solid fa-plug-circle-plus fa-sm"></i>
              <NavLink className="navlink" activeclass='active' to='/admin/dashboard/add-project' > Add Project</NavLink>
            </li>
            <li className="nav-item">
            <i className="fa-solid fa-plug fa-sm"></i>
              <NavLink className="navlink" activeclass='active' to='/admin/dashboard/projects' > Projects</NavLink>
            </li>
            <li className="nav-item">
              <i className="fa-solid fa-list fa-sm fa-fw"></i>
              <NavLink className="navlink" activeclass='active' to='/admin/dashboard/project/category' > Project Category</NavLink>
            </li>
            <li className="nav-item">
              <i className="fa-solid fa-graduation-cap fa-sm"></i>
              <NavLink className="navlink" activeclass='active' to='/admin/dashboard/courses' >Courses</NavLink>
            </li>
            <li className="nav-item">
              <i className="fa-solid fa-swatchbook fa-sm"></i>
              <NavLink className="navlink" activeclass='active' to='/admin/dashboard/create-post' > Post</NavLink>
            </li>
            <li className="nav-item">
              <i className="fa-solid fa-user-tag fa-sm"></i>
              <NavLink className="navlink" activeclass='active' to='/admin/dashboard/blog/author'> Authors</NavLink>
            </li>
            <li className="nav-item">
              <i className="fa-solid fa-list-check fa-sm"></i>
              <NavLink className="navlink" activeclass='active' to='/admin/dashboard/blog/category'>Blog Category</NavLink>
            </li>
            <li className="nav-item" id='logout'>
              {/* <span className="log" onClick={() => handleLogout()} > Logout</span> */}
              <i className="fa fa-sign-out log" aria-hidden="true" onClick={() => handleLogout()} ></i>
            </li>
          </ul>
        </div>
        <div className="main-content">
          <div className="menu-bar">
            <i className="fas fa-bars fa-lg fa-fw" onClick={toggler}></i>
            <div>
              <img src={require("../../utils/Img/client_testi.png")} alt="" className="img-fluid profileImg" />
            </div>
          </div>
          <div className="props">
            {props.children}  
          </div>
          
        </div>
      </div>
      <div className="text-center p-1 copyright" style={{backgroundColor: "#211E3B"}}>
        © 2021 Copyright
        <a className="text-reset mx-1 fw-bold" href="https://www.xpressdreams.com.ng" target='_blank' rel='noreferrer'>Xpress Dreams Technology</a>
        | All Rights Reserved.
      </div>
    </AdminWrapper>  
  )
}

const AdminWrapper = styled.div `
/* App container-div */
.container-div {
  display: flex;
  height: 100vh;
}
/* Sidebar (hidden on small devices) */
.sidebar {
  flex: 0 0 190px;
  background-color: #211e3b;
  color: #fff;
  display: none;
}

.sidebar ul {
  list-style: none;
  padding: 20px 10px;
}
.sidebar ul li{
  margin-bottom:7px;
}
i.fa-sm{
  margin-right:5px;
}
.navlink{
  color:#ccc;
  text-decoration:none;
  font-weight:600;
}
.navlink:hover{
  color:#91A5F9;
  font-weight:700;
}
.active{
  color:#91A5F9;
}
.log{
  font-size:1.4em;
  font-weight:800;
  margin-top:25px;
}
#logout{
  cursor:pointer;
}
#logout:hover{
  color:#f8311ff5;
}

.show-sidebar {
  display: block;
}
/* Main content area */
.main-content {
  flex: 1;
  padding: 0 0;
  overflow-y:scroll;
  position:relative;
  .props{
  }
}
.menu-bar{
  border-bottom: 1px solid #e5e5e5;
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
  padding:3px 0;
  width:100%;
  display:flex;
  align-items:center;
  justify-content:space-between;
  margin-bottom:15px;
  position:sticky;
  right:0;
  top:0px;
  z-index:1;
  background-color:#ccc;

  .fa-bars{
    cursor:pointer;
  }
  .profileImg{
    height:40px;
    width:40px;
    border-radius:50%;
    margin:0 5px 5px 5px;
  }
}

.copyright{
  color:#ccc;
  bottom:0;
  a{
    text-decoration:none;
    transition:1s ease all;
  }
  a:hover{
    color:#fff !important;
  }
}
/* Media queries */
@media (max-width:500px) {
  
}

`;