import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';


export const Nav = () => {
  return (
    <Wrapper>
      <div className="social-icons">
        <div>
          <i className="fa fa-envelope mr-2 text-white icons" aria-hidden="true"></i>
          <a href="mailto:info@xpressdreams.com.ng" className="text-white-50">info@xpressdreams.com.ng</a>
        </div>
        <div >
          <i className="fab fa-whatsapp mx-2 icons whatsapp" aria-hidden="true"></i>
          <i className="fab fa-twitter-square mx-2 icons" aria-hidden="true"></i>
          <i className="fab fa-facebook-square mx-2 icons" aria-hidden="true"></i>
          <i className="fab fa-instagram mx-2 icons" aria-hidden="true"></i>
        </div>
      </div>
      <nav className="navbar navbar-expand-sm navbar-light border-bottom box-shadow" id="myNavbar">
        <div className="container-fluid">
            <NavLink className="navbar-brand"  href="/">
              <img src={require('../utils/Img/Edited.png')}  alt= "" className="img-fluid logo" />
            </NavLink>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
                >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="navbar-collapse collapse" id='navbarSupportedContent'>
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <NavLink className="navlink" activeclass="active" aria-current="page"  to="/">Home</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="navlink" activeclass="active"  to="/career" >Career</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="navlink" activeclass="active"  to="/services">Services</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="navlink" activeclass="active"  to="/research">Research</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="navlink" activeclass="active"  to="/blog">Blog</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="navlink" activeclass="active"  to="/about" >About</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="navlink" activeclass="active"  to="/contact">Contact</NavLink>
                </li>
              </ul>
            </div>
        </div>
      </nav>
    </Wrapper>
  )
}

const Wrapper = styled.div `
  .social-icons{
    background:#000;
    color:#ccc;
    display:flex;
    padding:10px 15px;
    align-items:center;
    justify-content:space-between;

    div a{
      text-decoration:none;
      margin-left: 5px;
    }

    @media screen and (max-width:380px){
      padding:5px;
      div a{
        font-size:12px;
      }
    }
  }
  .border-bottom {
    border-bottom: 1px solid #e5e5e5;
  }

  .box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
  }
  #myNavbar{
    @media screen and (max-width:480px){
      .navbar-brand{
        width:75%;
      }
      .logo{
        width:100%;
      }
      .navbar-nav{
        text-align:left;
      }
    }
    
  }
  .navlink{
    color:#000;
    text-decoration:none;
    margin:0 5px;
  }
  .active{
    color:#91A5F9;
  }
`;