import React from 'react'
import styled from 'styled-components'


export default function Footer() {
  return (
    <Wrapper>

      <footer className="text-center text-lg-start bg-light text-muted">
        <section className="d-flex justify-content-center justify-content-lg-between p-2 border-bottom">
          <div className="me-5 d-none d-md-block d-lg-block">
            <span>Get connected with us on social networks</span>
          </div>
          
          <div>
            <a    href="/" className="me-4 text-reset">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a    href="/" className="me-4 text-reset">
              <i className="fab fa-twitter"></i>
            </a>
            <a    href="/" className="me-4 text-reset">
              <i className="fab fa-google"></i>
            </a>
            <a    href="/" className="me-4 text-reset">
              <i className="fab fa-instagram"></i>
            </a>
            <a    href="/" className="me-4 text-reset">
              <i className="fab fa-linkedin"></i>
            </a>
          </div>
        </section>

        <section className="sect">
          <div className="container-fluid  text-md-start">
            <div className="row pt-4">
              <div className="col-md-3 col-lg-4 col-xl-3  mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                  <img className='logo' src={require("../utils/Img/logo192.png")} alt="logo"/> Xpress Dreams
                </h6>
                <p>
                  We provide unique and premium solutions geared towards 
                  meeting the business objectives of our clients.
                </p>
              </div>

              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                  Services
                </h6>
                <p>
                  <a href="/career" className="text-reset">Career</a>
                </p>
                <p>
                  <a href="/career" className="text-reset">Mentorship</a>
                </p>
                <p>
                  <a href="/services" className="text-reset">Software Development</a>
                </p>
                <p>
                  <a href="/contact" className="text-reset">IT Consultancy</a>
                </p>
              </div>

              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                  Useful links
                </h6>
                <p>
                  <a href="/" className="text-reset">Home</a>
                </p>
                <p>
                  <a href="/career" className="text-reset">Career</a>
                </p>
                <p>
                  <a href="/research" className="text-reset">Research</a>
                </p>
                <p>
                  <a href="/blog" className="text-reset">Blog</a>
                </p>
              </div>

              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
                <p>
                  <a href='mailto:info@xpressdreams.com.ng'>
                    <i className="fas fa-envelope me-3"></i>
                    info@xpressdreams.com.ng
                  </a>
                </p>
                <p>
                  <a href='mailto:support@xpressdreams.com.ng'>
                    <i className="fas fa-envelope me-3"></i>
                    support@xpressdreams.com.ng
                  </a>
                </p>
                <p><i className="fas fa-phone me-3"></i> +234 (0) 703 430 1877</p>
              </div>
            </div>
          </div>
        </section>

        <div className="text-center p-4 copyright" style={{backgroundColor: "#211E3B"}}>
          © 2021 Copyright
          <a className="text-reset mx-1 fw-bold" href="https://www.xpressdreams.com.ng" target='_blank' rel='noreferrer'>Xpress Dreams Technology</a>
          | All Rights Reserved.
        </div>
      </footer>
      
    </Wrapper>
  )
}

const Wrapper = styled.div `
  width:98%;
  margin:0 auto;
  section{
    background:#2B2B45;
  }
  .sect{
    .logo{
      border-radius:25%;
      width:30px;
    }
    @media screen and (max-width:480px){
      text-align: left !important;
      padding:10px;
    }
    p a{
      text-decoration:none;
      color:inherit;
    }
    p a:hover{
      color:#91A5F9 !important;
    }
  }
  .copyright{
    a{
      text-decoration:none;
      transition:1s ease all;
    }
    a:hover{
      color:#fff !important;
    }
  }
`;