import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {Axios} from '../../utils/middleware/config';
import { ResearchHero } from './ResearchHero';
import { CloudinaryImageUrl } from '../../utils/helper';
// import { Link } from 'react-router-dom';



export const Projects = ({topicModal}) => {

  const [projects, setProjects] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrevious, setPrevious] = useState(false);
  const [nextPage, setNextPage] = useState();
  const [previousPage, setPreviousPage] = useState();

  const loadProjects = async(page) => {
    try {
      const {data} = await Axios.get(`/projects?page=${page}`);
      if(data){
        setHasNext(data.data.hasNextPage);
        setPrevious(data.data.hasPreviousPage);
        setNextPage(data.data.nextPage);
        setPreviousPage(data.data.previousPage);
        setProjects(data.data.projects);
      }
    } catch (error) {
      
    }
    
  }
  const handleNext = () => setPageNumber(nextPage);
  const handlePrevious = () => setPageNumber(previousPage);

  const handleProjectSearch = async(title) => {
    try {
      console.log(title);
      const {data} = await Axios.get(`/project/${title}`);
      if(data.success){
        setProjects(data.data)
      }
    } catch (error) {
      
    }
  }

  useEffect(() =>{
    loadProjects(pageNumber)
  },[pageNumber]);

  return (
    <Wrapper>
      <ResearchHero modal={topicModal} showProject={handleProjectSearch}/>
      <div id="sample_project" className="container-fluid">
        <h4>Latest Student Projects</h4>
        <hr className="w-25"/>
        <div className="row">
          {projects.length < 1 && <p>No Projects yet! </p>}
          {projects.map((proj) =>{
            return <div  key={proj.id} className='col-md-4 col-sm-12'>
              <div className="project_wrap">
                <img src={CloudinaryImageUrl(proj.image_url)} className="card-img-top" alt="..."/>
                {/* <XpressImage publicId={proj.image_url} type="project"/> */}
                <div className="card-body">
                  <h5 className="card-title">{proj.title}</h5>
                  <p className="card-text">
                    {proj.description}
                  </p>
                  <a href={`/research/project/${proj.id}`} className="btn btn-primary">Get Project</a>
                </div>
              </div>
            </div>
          })}
        </div>

        <div id="pagination_div">
          {hasPrevious && <button className="prev" onClick={() => handlePrevious()}>Previous</button>}
          {hasNext && <button className="next" onClick={() => handleNext()}>Next</button>}
        </div>
      </div>

    </Wrapper>
  )
}

const Wrapper = styled.div `

  #sample_project {
    /*background: #F4F5FA;*/
    background: url(${require("../../utils/Img/bg-testi-home8.png")});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding:4% 2% 4% 2%;
    clear:both;

    @media screen and (max-width:768px){
      padding:4% 1%;
    }
    @media screen and (max-width:480px){
      padding:4% 3%;
      mim-height:800px !important;
      overflow-y: auto;
    }

    h4{
      font-weight:800;
    }

    .project_wrap {
      position: relative;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      margin-bottom: 25px;
      height: 470px;

      @media screen and (max-width:768px){
        height:470px;
      }
      img{
        width:100%;
        max-height:250px;
        aspect-ratio:1/3;
      }
      .card-body{
        padding:10px;
      }
      .card-title{
        font-weight:700;
        margin-bottom:5px;
        font-size:18px;
        line-height:1;
      }
      .card-text{
        display: -webkit-box;
        overflow : hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical; 

        @media screen and (max-width:768px){
        }
      }
      .btn {
        position: absolute;
        bottom: 10px;
        background: rgba(126,214,250,0.823);
        border: none;
      }
    }

    #pagination_div{
      padding:3px;
      width: max-content;
      float:right;

      .prev,.next{
        border-radius:5px;
        padding:5px 15px;
        font-weight:700;
        font-size:14px;
        background:#3a057efb;
        border:none;
        color:#fff;
      }
      .prev:hover,.next:hover{
        background:#2c0a4eb2;
      }
      .prev{
        margin-right:10px;
      }
    }
  }

  
`;