import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {Axios} from '../../utils/middleware/config';
import { AdminLayout } from './AdminLayout';

export const Order = () => {

  const [clientOrders, setClientOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrevious, setHasPrevious] = useState(false);
  const [nextPage, setNextPage] = useState(0);
  const [previousPage, setPreviousPage] = useState(0);
 

  const loadOrders = async(page) =>{
    const {data} =await Axios.get(`/client/projects/order?page=${page}`);
    if(data){
      console.log(data.data);
      const {orders,hasNextPage,nextPage,hasPreviousPage,previousPage} = data.data;
      setClientOrders(orders)
      setNextPage(nextPage);
      setHasNext(hasNextPage);
      setPreviousPage(previousPage);
      setHasPrevious(hasPreviousPage);
    }
  }
  const handleNextPage = () =>{setCurrentPage(nextPage);loadOrders(nextPage)}
  const handlePreviousPage = () =>{setCurrentPage(previousPage);loadOrders(previousPage)}
  
  useEffect(() =>{
    if(currentPage === 0){
      loadOrders();
    }
  },[currentPage]);

  return (
    <Wrapper>
      <AdminLayout>
        <div className='contents'>
          <table className="table mt-3">
            <thead className='thead-dark'>
              <tr>
                <th>#</th>
                <th>Order Date</th>
                <th>Reference ID</th>
                <th>Name</th>
                <th>Mobile</th>
                <th>Email</th>
                <th>Project</th>
                <th>PRICE</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {clientOrders.map((order,index) =>{
                return<tr key={order.id}>
                  <td>{++index}</td>
                  <td>{new Date(order.created_at).toLocaleString()}</td>
                  <td>{order.reference_no}</td>
                  <td>{order.name}</td>
                  <td>{order.mobile}</td>
                  <td>{order.email}</td>
                  <td>{order.project}</td>
                  <td>{parseInt(order.amount)}</td>
                  <td>{order.status}</td>
                  <td>
                    <i className="fas fa-edit fa-sm editTag me-1" aria-hidden="true" data-bs-toggle="modal" data-bs-target="#orderUpdateModal"></i>
                    <i className="fa fa-trash mt-2 deleteTag ms-3" aria-hidden="true"></i>
                  </td>
                </tr>
                }) 
              }
            </tbody>
          </table>
          <div className="pagination">
            {hasPrevious && <button className="prev me-2" onClick={handlePreviousPage}>Previous</button>}
            {hasNext && <button className="next ms-2" onClick={handleNextPage}>Next</button>}
          </div>
        </div>
        {/* Status Update Modal */}
        <div className="modal" tabIndex="-1" id='orderUpdateModal' data-bs-backdrop="static">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Update Client Order</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="status">
                  <label className="ms-2" htmlFor="status"><input type="radio" name="status"/> Pending</label>
                  <label className="ms-2" htmlFor="status"><input type="radio" name="status"/> Delivered</label>
                  <label className="ms-2" htmlFor="status"><input type="radio" name="status"/> Canceled</label>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                <button type="button" className="btn btn-primary">Update</button>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    </Wrapper>
  )
}

const Wrapper = styled.div `
  .contents{
    @media screen and (max-width:768px){
      overflow-x: auto;
    }
  }
 
  td{
    font-size:14px;
  }
  .editTag, .deleteTag{
    cursor:pointer;
    font-weight: 800;
    font-size:1.2em;
    transition: 1s ease all;
  }
  .editTag:hover, .deleteTag:hover{
    transform: scale(1.2);
  }
  .editTag{
    color:#3f8df2f1;
  }
  .deleteTag{
    color:#f26659f1;
  }
  
`;